
#google_translate_element {
	display: inline-block;
	position: relative;
	top: -1px;

	&.pole {
		background-color: transparent;
		margin-left: 15px;
	}
	
	.goog-te-gadget-simple {
		border: 0;
		width: 100%;
		padding-bottom: 1px;
		background-color: transparent;
		
		&,
		& * {
			font-size: rem(16px) !important;
		}
		
		.goog-te-gadget-icon {
			background-color: #ffffff;
		}

		[aria-haspopup][href='#'] {
			margin-right: 0;
			display: inline-block;
			text-decoration: none !important;

			span {
				padding: 0 3px;
				display: inline-block;
				color: #000000;
			
				&:nth-of-type(1),
				&:nth-of-type(2) {
					display: none;
				}
				
				&:nth-of-type(3) {
					color: #ffffff !important;
					font-size: rem(14px) !important;
				}
			}

			img {
				display: none;
			}
		}
	}
}

@include bp("< 600px") {
	#google_translate_element,
	#goog-gt-tt,
	#goog-gt-tt + div > object,
	iframe.goog-te-menu-frame,
	iframe.goog-te-banner-frame {
		display: none!important;
	}

	body {
		top: 0 !important;
	}
}