
/**************************
* OBECNÉ
**************************/

html {
	touch-action: manipulation;
}

html,
body {
	background-color: #ffffff;
}

body {
	@extend %reset;
	text-align: center;
}

@include selection {
	background-color: $color-main;
	color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
	border-radius: 3px;
	box-shadow: 0 0 0 2000px $link-focus-bg inset, 0 0 0 3px $link-focus-bg !important;
	color: $link-focus-text !important;
	outline: 0;
}

img {
	color: inherit;

}


/*************************
* HLAVNÍ OBSAH
*************************/

.centrovany {
	@extend %sf;
	width: 94%;
	max-width: $max-width;
	margin: 0 auto;
	text-align: left;

	&.-wide {
		max-width: 1920px;
	}
}

#stranka #obalcelek {
	background: transparent url(images/bg_wm.svg) no-repeat 105% 100%;
	padding-bottom: 10%;
}
 
#stred,
#vpravo,
#vlevo {
	@extend %border-box;
	width: 100%;
	margin-bottom: 30px;
}

#vpravo,
#vlevo {
	word-wrap: break-word;
}

#stranka #stred {
	padding: 15px 6% 40px 6%;
}


/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

.section {
	padding: 50px 0;

	&.-bg {
		background-color: $color-light;
	}

	h2.cvi {
		margin-bottom: 25px;
	}
}

@include bp("> 800px") {
	#stranka #telo {
		display: flex;
		justify-content: space-between;
	}

	#stred {
		width: 100%; //IE 
		order: 1;
	}

	#vlevo {
		width: 370px;
		flex-shrink: 0;
		background-color: $color-light;
		position: relative;
		@include pad($color-light, left);
	}
}


/************************
* PRVKY STRÁNKY
************************/

.cesta {
	font-size: rem(15px);
	padding: 16px 0 10px 0;
	margin: 0;
	position: relative;
	text-align: right;
	color: #575757;
	
	@include link {
		color: #575757;
	}

	strong {
		text-transform: uppercase;
		font-weight: normal;
	}
}

.box {
	padding: 0 15px 10px 15px;
	background: #cccccc;
	margin: 0 0 15px 0;
}

.dalsi {
	font-size: rem(22px);
	margin: 15px 0 0 0; 
	padding: 5px 0 0 0;
	text-align: center;
	font-family: $font-headings;

	@include link {
		color: #222222;
		text-decoration: underline;
	}
	
	@include link-over {
		text-decoration: none;
	} 

	@include bp("> 800px") {	 
		text-align: left;
	}
}
#obalcelek{
    .strlistovani,
    #listovani{
        img{
            background-color: #444;
        }
        .aktivni{
            img{
                background-color: #000!important;
            }
            &:hover,
            &:focus{
                img{
                    background-color: #444!important;
                }
            }
        }
    }
}

/************************
* ZÁPATÍ
************************/

#nadpatou {
	background: $color-main;
	padding: 50px 0 25px;

	::selection {
		background-color: #ffffff;
		color: #222222;
	}

	&,
	h2.cvi,
	h3.cvi,
	#{selector-link()} {
		color: #ffffff;
	}

	h2.cvi {
		font-size: rem(22px);
		font-weight: bold;
		margin-bottom: 15px;
	}

	address {
		font-style: normal;

		.kontprvni {
			font-weight: normal;	
		}
	}

	.dalsi {
		font-size: rem(17px);
		font-weight: bold;
	}

	.cols {
		@extend %sf;
	}

	.col {
		float: left;
		width: 27%;
		margin-left: 3%;
		margin-bottom: 25px;
		font-size: rem(17px);

		@include bp("< 650px") {
			width: 48%;
			
			&:last-child {
				margin-left: 4%;
			}
			
			&:nth-child(2) {
				margin-left: 0;
			}
		}
        @include bp("< 450px") {
			width: 100%;
			
			&:last-child {
				margin-left: 0;
			}
		}

		&.-links {
			width: 40%;
			margin-left: 0;
			
			@include bp("< 650px") {
				width: 100%;
			}

			@include bp("> 400px", "< 650px") {
				ul {
					column-count: 2;
					column-gap: 30px; 
					break-inside: avoid;
					page-break-inside: avoid;
				}
			}

			@include bp("> 1120px") {
				ul {
					column-count: 2;
					column-gap: 30px; 
					break-inside: avoid;
					page-break-inside: avoid;
				}
			}
		}
	}
}


#obalpata {
	background-color: $footer-background;
	//min-width: 320px;
}

#pata {
	text-align: center;
	padding: 22px 16px 28px;
	color: $footer-text; 
	box-sizing: border-box;
	font-size: rem(14px);

	* {
		line-height: 2.2;
		border: 0;
	}

	li + li::before{
		content: "\|";
		margin: 0 8px 0 0;
	}

	.partWebmaster1::before{
		content: "\|";
		margin: 0 10px 0 0;
	}

	@include link {
		color: $footer-link;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}
	
	.patalogo {
		padding: 1px 0;
		margin-right: 11px;
	}

	#pocitadlo{
		margin: 0!important;
		padding-bottom: 0!important;
	}

	.webmaster,
	.inline {
		display: inline-block;
		margin: 0;
		padding: 0 0 0 3px;

		li {
			padding: 0 5px;

			a {
				margin: 0 0 0 1px;
			}
		}
	}

	ul.inline {
		display: block;
	}

	.webmaster {
		display: block;

		#stranka &{
				margin-top: 2px;
			}

		.partWebmaster {
			display: inline-block;
			margin-left: 5px;
		}
	}

	ul.inline li {
		display: inline-block;
	}
}

@include bp("< 500px"){
	.partWebmaster1 {
		display: block!important;
		margin-top: 2px;

		&::before{
			content: ""!important;
			margin:0!important;
		}	
	}	
}

#unpobtekane {
	margin-left: 0 !important;
}


@if is-bit-set($web_bitmask, $BIT_SKRYT_KONTEXT) {
	.zobrazeno.kontext2 {
		display: none;
	}
}

@if is-bit-set($web_bitmask, $BIT_SKRYT_ZODPOVIDA) {
	.popis.dpopis .zodpovida {
		display: none;
	}
}