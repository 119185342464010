
/******************
* MODULY
******************/

.logo {
    margin: 0 0 10px;
    @include max(599px) {
        text-align: center;
    }
}

#uvodtitul {
	overflow: hidden;
	margin: 0 0 30px 0;
}

#upozorneni {
	padding: 15px;
	background-color: pink;

	&,
	h2.cvi,
	#{selector-link()} {
		color: #444444;
	}

	h2.cvi,
	p {
		display: inline-block;
	}
}

.inpocasi {
	color: #ffffff;	
}

#uhodiny {
	.uhden {
		display: inline-block;
		width: 4.4em;
	}
}

#kalendarAkci {
	.editor {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}

#obalmbannery{
	min-width: 320px;
	background: #ffffff;
}

#mbannery {
	padding: 30px 0;
	color: #000000;
	margin: 0;

	.editor {
		text-align: center;

		@include link-all {
			color: #000000 !important;
		}
	}
	
	li { 
		margin-top: 0;
		margin-bottom: 0;
	}
}

.kontaktpata {
	.socialni {
		text-align: left;
		padding: 0;
		margin-top: 20px;

		li {
			margin: 0 10px 10px 0;

			&::before {
				content: normal;
			}
		}
	}
}

#jmeniny {
	padding: 10px 12px 9px 94px;
	text-align: left;

	@include bp("< 1280px") {
		padding-left: 76px;
	}
	
	* {
		display: inline;
	}
}

#kontakt {
	background-color: rgba(#000000, .65);
	color: #ffffff;
	padding: 30px;
	box-sizing: border-box;
	margin-bottom: 12px;

	h2.cvi {
		font-size: rem(32px);
		color: inherit;
	}

	@include link {
		color: inherit;
	}

	address {
		display: block;
		font-style: normal;
		margin: 0;
	}
	
	strong {
		font-weight: bold;
	}
    @include min(600px){
        max-width: 256px;
    }
}

#anketa {
	p {
		padding: 0;
		margin: 0 0 1em 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 8px 0 5px 0;

		li {
			margin-bottom: 8px;
		}
	}

	.hlas {
		margin-top: 3px;
		z-index: 1;
		
		div {
			height: 12px;
			background-color: $color-main;
		}
	}
}

#pocitadlo {
	margin: 15px 0 0 0;
	text-align: center;
	padding-bottom: 15px;

	ul.inline {
		padding-left: 0;
		display: inline;

		li span {
			margin: 0 0 0 2px;
		}
	}

	@include bp("< 600px"){
		li {
			display:block!important;
	
			+ li::before{
				content: "";
				margin:0;
			}	
		}
	}
}

