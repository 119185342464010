@charset "UTF-8";
/*!
  Author: WEBHOUSE, s. r. o.
  Version: 1.2
*/
/** PROMĚNNÉ PRO NASTAVENÍ ECCO **/
/*********************************/
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
#lista ul, .dok ul, .obrodkazy .ui, .titulodkazy .ui, .zalozky ul, .bezjs ul, #menu1 ul, #menu ul, body, .obrodkazy li, #hledani .btn, #keskryti, #titul .zalozky li, #stranka #kalakci .mesice {
  margin: 0;
  padding: 0;
}

#lista ul, .dok ul, .obrodkazy .ui, .titulodkazy .ui, .zalozky ul, .bezjs ul, #menu1 ul, #menu ul {
  list-style-type: none;
}

#nazev a::after, .titulformular label.label-skryty::after, #hledani label.label-skryty::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#kalakci .calcells td .desc {
  position: absolute;
  left: -9999em;
  top: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  outline: 0;
}

#stred,
#vpravo,
#vlevo, .odkazy .ui li li li::before,
.aktuality2 li.bezobr li li::before,
.utvary .ui li.o li li::before,
.kategorie .ui li li li::before,
.utvary .ui li li li::before,
.galerie .ui li li li::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.centrovany::after, #nadpatou .cols::after, #lista::after, .zalozky ul::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
/***********************************
/* HODNOTY BITŮ PRO NASTAVENÍ WEBU 
***********************************/
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/
/************************/
/*************************
* PROMĚNNÉ KOMPONENT
*************************/
/** TYPOGRAFIE **/
/** FORMULÁŘE **/
/** TABULKY **/
/** SEZNAMY **/
/** AKCE **/
/** KALENDÁŘE **/
/** Hodnoty je lepší zadávat v em **/
/** MENU **/
/** ZÁLOŽKY **/
/** PATA **/
/************************/
/***********************************************************
* PROMĚNNÉ BREAKPOINTŮ PRO SNADNĚJŠÍ ZADÁVÁNÍ MIXINU "BP"
***********************************************************/
/**********************************************************/
html {
  line-height: 1.375;
  font-family: "Mukta Vaani";
  font-size: 18px;
}

@media screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

body.editor {
  background-color: #ffffff;
}

.editor h3 {
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  font-family: "Mukta Vaani";
  line-height: 1.2;
}

body,
.editor,
body.editor,
body.editor td,
body.editor th {
  font-size: 1em;
}

body,
.automat {
  color: #444444;
}

a,
a:link,
a:visited {
  color: #232323;
  text-decoration: underline;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

hr,
hr.oddelovac {
  color: #dddddd;
  background-color: #dddddd;
}

body,
input,
button,
textarea,
select {
  font-family: "Mukta Vaani";
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
  color: #BD013E;
  background-color: #ffffff;
  padding: 0 3px;
}

.vystraha {
  border-radius: 3px;
}

.zvyrazneni2 {
  background-color: #303030;
  color: #ffffff;
  padding: 0 2px;
}

strong.zprava {
  font-size: 1.1em;
}

/*******************
* NADPISY
*******************/
h1.cvi {
  font-size: 0.9375rem;
  padding: 0;
  line-height: 1.1;
  margin: 0 20px 0 0;
  font-family: "Titillium Web";
  font-weight: normal;
}

h1.cvi strong {
  font-size: 2.125rem;
  font-weight: normal;
  display: block;
  line-height: 1;
}

@media screen and (min-width: 501px) {
  h1.cvi strong {
    font-size: 2.75rem;
  }
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
  font-family: "Titillium Web";
}

h2.cvi,
.poz h2.cist {
  font-size: 2.5rem;
  color: #222222;
  font-weight: 600;
  margin: 0;
  padding: 8px 0;
  line-height: 1.2;
}

#zahlavi h2.cvi {
  margin: 20px 0 30px 0;
  padding: 0;
}

#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: 2.5rem;
}

.editor h3, h3.cvi, h3.ud,
#prohlaseni h3 {
  font-size: 2.1875rem;
  font-weight: normal;
  margin: 25px 0 5px 0;
  padding: 8px 20px 4px 0;
  line-height: 1.2;
}

h4 {
  font-size: 1.875rem;
  line-height: 1.2;
}

h4.urd-list {
  font-size: 1.5625rem;
  padding-top: .75em;
  float: none;
  border-top: 3px #cccccc solid;
  padding-right: 7em;
}

h4.urd-list + .udz.urd-list {
  margin-top: -1.6em;
  margin-bottom: 2em;
  float: none;
  text-align: right;
}

@media screen and (max-width: 1023px) {
  h4.urd-list {
    padding-right: 0;
  }
  h4.urd-list + .udz.urd-list {
    margin-top: 1em;
  }
}

h5 {
  font-size: 1.1em;
}

/*************************
* ŠABLONY
*************************/
/*************************
* DOKUMENTY
*************************/
#pozicovani .cards .card {
  margin-top: 0;
  overflow: hidden;
  box-sizing: border-box;
  flex-grow: 1;
}

#pozicovani .cards .card img {
  float: none;
  display: table;
  margin: 0;
  width: 100%;
}

#pozicovani .cards .card .dok-datum {
  margin-top: 10px;
  font-weight: bold;
  color: #222222;
  font-family: "Titillium Web";
  font-size: 1.1875rem;
}

#pozicovani .cards .card .dok-upoutavka {
  width: 100%;
  display: block;
}

#pozicovani .cards .card .dok-upoutavka:empty::before {
  content: "";
  display: block;
  background: #ffffff url(images/noimage.svg) no-repeat 50% 50%/80px auto;
  width: 160px;
  height: 105px;
}

#pozicovani .cards .card .dok-ktg {
  color: #6D6D6D;
  font-weight: 300;
  font-size: 1.0625rem;
}

#pozicovani .cards .card .dok-ktg a,
#pozicovani .cards .card .dok-ktg a:link,
#pozicovani .cards .card .dok-ktg a:visited {
  color: #6D6D6D;
}

#pozicovani .cards .card div {
  margin-top: 8px;
}

@media screen and (min-width: 500px) {
  #pozicovani .cards .card {
    padding-left: 185px;
  }
  #pozicovani .cards .card .dok-upoutavka {
    float: left;
    display: block;
    margin-left: -185px;
    width: auto;
  }
  #pozicovani .cards .card .dok-upoutavka img {
    margin-bottom: 10px;
    width: 160px;
  }
}

/**************************
* OBECNÉ
**************************/
html {
  touch-action: manipulation;
}

html,
body {
  background-color: #ffffff;
}

body {
  text-align: center;
}

::-moz-selection {
  background-color: #107437;
  color: #ffffff;
}

::selection {
  background-color: #107437;
  color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
  border-radius: 3px;
  box-shadow: 0 0 0 2000px #303030 inset, 0 0 0 3px #303030 !important;
  color: #ffffff !important;
  outline: 0;
}

img {
  color: inherit;
}

/*************************
* HLAVNÍ OBSAH
*************************/
.centrovany {
  width: 94%;
  max-width: 1230px;
  margin: 0 auto;
  text-align: left;
}

.centrovany.-wide {
  max-width: 1920px;
}

#stranka #obalcelek {
  background: transparent url(images/bg_wm.svg) no-repeat 105% 100%;
  padding-bottom: 10%;
}

#stred,
#vpravo,
#vlevo {
  width: 100%;
  margin-bottom: 30px;
}

#vpravo,
#vlevo {
  word-wrap: break-word;
}

#stranka #stred {
  padding: 15px 6% 40px 6%;
}

/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
.section {
  padding: 50px 0;
}

.section.-bg {
  background-color: #F0F0F0;
}

.section h2.cvi {
  margin-bottom: 25px;
}

@media screen and (min-width: 801px) {
  #stranka #telo {
    display: flex;
    justify-content: space-between;
  }
  #stred {
    width: 100%;
    order: 1;
  }
  #vlevo {
    width: 370px;
    flex-shrink: 0;
    background-color: #F0F0F0;
    position: relative;
  }
  #vlevo::before {
    content: "";
    position: absolute;
    width: 999em;
    right: 100%;
    top: 0;
    bottom: 0;
    background-color: #F0F0F0;
  }
}

/************************
* PRVKY STRÁNKY
************************/
.cesta {
  font-size: 0.9375rem;
  padding: 16px 0 10px 0;
  margin: 0;
  position: relative;
  text-align: right;
  color: #575757;
}

.cesta a,
.cesta a:link,
.cesta a:visited {
  color: #575757;
}

.cesta strong {
  text-transform: uppercase;
  font-weight: normal;
}

.box {
  padding: 0 15px 10px 15px;
  background: #cccccc;
  margin: 0 0 15px 0;
}

.dalsi {
  font-size: 1.375rem;
  margin: 15px 0 0 0;
  padding: 5px 0 0 0;
  text-align: center;
  font-family: "Titillium Web";
}

.dalsi a,
.dalsi a:link,
.dalsi a:visited {
  color: #222222;
  text-decoration: underline;
}

.dalsi a:hover,
.dalsi a:focus,
.dalsi a:active {
  text-decoration: none;
}

@media screen and (min-width: 801px) {
  .dalsi {
    text-align: left;
  }
}

#obalcelek .strlistovani img,
#obalcelek #listovani img {
  background-color: #444;
}

#obalcelek .strlistovani .aktivni img,
#obalcelek #listovani .aktivni img {
  background-color: #000 !important;
}

#obalcelek .strlistovani .aktivni:hover img, #obalcelek .strlistovani .aktivni:focus img,
#obalcelek #listovani .aktivni:hover img,
#obalcelek #listovani .aktivni:focus img {
  background-color: #444 !important;
}

/************************
* ZÁPATÍ
************************/
#nadpatou {
  background: #107437;
  padding: 50px 0 25px;
}

#nadpatou ::selection {
  background-color: #ffffff;
  color: #222222;
}

#nadpatou,
#nadpatou h2.cvi,
#nadpatou h3.cvi,
#nadpatou a, #nadpatou a:link, #nadpatou a:visited {
  color: #ffffff;
}

#nadpatou h2.cvi {
  font-size: 1.375rem;
  font-weight: bold;
  margin-bottom: 15px;
}

#nadpatou address {
  font-style: normal;
}

#nadpatou address .kontprvni {
  font-weight: normal;
}

#nadpatou .dalsi {
  font-size: 1.0625rem;
  font-weight: bold;
}

#nadpatou .col {
  float: left;
  width: 27%;
  margin-left: 3%;
  margin-bottom: 25px;
  font-size: 1.0625rem;
}

@media screen and (max-width: 649px) {
  #nadpatou .col {
    width: 48%;
  }
  #nadpatou .col:last-child {
    margin-left: 4%;
  }
  #nadpatou .col:nth-child(2) {
    margin-left: 0;
  }
}

@media screen and (max-width: 449px) {
  #nadpatou .col {
    width: 100%;
  }
  #nadpatou .col:last-child {
    margin-left: 0;
  }
}

#nadpatou .col.-links {
  width: 40%;
  margin-left: 0;
}

@media screen and (max-width: 649px) {
  #nadpatou .col.-links {
    width: 100%;
  }
}

@media screen and (min-width: 401px) and (max-width: 649px) {
  #nadpatou .col.-links ul {
    column-count: 2;
    column-gap: 30px;
    break-inside: avoid;
    page-break-inside: avoid;
  }
}

@media screen and (min-width: 1121px) {
  #nadpatou .col.-links ul {
    column-count: 2;
    column-gap: 30px;
    break-inside: avoid;
    page-break-inside: avoid;
  }
}

#obalpata {
  background-color: #ffffff;
}

#pata {
  text-align: center;
  padding: 22px 16px 28px;
  color: #444444;
  box-sizing: border-box;
  font-size: 0.875rem;
}

#pata * {
  line-height: 2.2;
  border: 0;
}

#pata li + li::before {
  content: "\|";
  margin: 0 8px 0 0;
}

#pata .partWebmaster1::before {
  content: "\|";
  margin: 0 10px 0 0;
}

#pata a,
#pata a:link,
#pata a:visited {
  color: #444444;
  text-decoration: underline;
}

#pata a:hover,
#pata a:focus,
#pata a:active {
  text-decoration: none;
}

#pata .patalogo {
  padding: 1px 0;
  margin-right: 11px;
}

#pata #pocitadlo {
  margin: 0 !important;
  padding-bottom: 0 !important;
}

#pata .webmaster,
#pata .inline {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 3px;
}

#pata .webmaster li,
#pata .inline li {
  padding: 0 5px;
}

#pata .webmaster li a,
#pata .inline li a {
  margin: 0 0 0 1px;
}

#pata ul.inline {
  display: block;
}

#pata .webmaster {
  display: block;
}

#stranka #pata .webmaster {
  margin-top: 2px;
}

#pata .webmaster .partWebmaster {
  display: inline-block;
  margin-left: 5px;
}

#pata ul.inline li {
  display: inline-block;
}

@media screen and (max-width: 499px) {
  .partWebmaster1 {
    display: block !important;
    margin-top: 2px;
  }
  .partWebmaster1::before {
    content: "" !important;
    margin: 0 !important;
  }
}

#unpobtekane {
  margin-left: 0 !important;
}

.popis.dpopis .zodpovida {
  display: none;
}

/*************************
* ZÁHLAVÍ
*************************/
/*************************
* HLAVA
*************************/
#obalhlava {
  position: relative;
  z-index: 13;
  background-color: #107437;
  color: #ffffff;
}

#titul #obalhlava {
  border-top: 18px #ffffff solid;
}

#obalhlava ::selection {
  background-color: #ffffff;
  color: #222222;
}

#obalhlava a,
#obalhlava a:link,
#obalhlava a:visited {
  color: #ffffff;
}

#hlava {
  clear: both;
  position: relative;
  z-index: 3;
}

.hlava {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1279px) {
  .hlava {
    flex-wrap: wrap;
  }
}

.hlava__section {
  margin: 15px 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 599px) {
  .hlava__section:last-child {
    flex-wrap: wrap;
  }
}

.hlava__section.-middle {
  margin: 0;
}

@media screen and (max-width: 1279px) {
  .hlava__section.-middle {
    padding-top: 10px;
    order: 3;
    width: 100%;
  }
}

.podzahlavim {
  position: relative;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.podzahlavim > div {
  width: 100%;
}

@media screen and (min-width: 600px) {
  .podzahlavim > div {
    width: auto;
  }
}

.podzahlavim::after {
  content: normal !important;
}

.podzahlavim .zahlaviodkazy {
  display: flex;
  flex-direction: column;
}

.podzahlavim .zahlaviodkazy a {
  display: inline-block;
  padding: 22px 20px;
  background-color: rgba(0, 0, 0, 0.65);
  text-align: center;
  min-width: 9.35em;
  min-height: 9.35em;
  font-family: "Titillium Web";
  box-sizing: border-box;
}

.podzahlavim .zahlaviodkazy a.webkamera {
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 100px;
  background-image: url(images/webkamera.svg);
  background-repeat: no-repeat;
  background-position: 50% 25px;
}

.podzahlavim .zahlaviodkazy a.webkamera span {
  font-size: 1.25rem;
}

.podzahlavim .zahlaviodkazy a.odkpocasi {
  text-decoration: none !important;
}

.podzahlavim .zahlaviodkazy a.odkpocasi #pocasi_teplota_vnejsi_nejnovejsi {
  font-size: 2.375rem;
  font-weight: 600;
}

.podzahlavim .zahlaviodkazy a.odkpocasi #pocasi_vlhkost_vnejsi_nejnovejsi {
  font-size: 1.375rem;
  font-weight: normal;
}

.podzahlavim .zahlaviodkazy a,
.podzahlavim .zahlaviodkazy a:link,
.podzahlavim .zahlaviodkazy a:visited {
  color: #ffffff;
  text-decoration: underline;
}

.podzahlavim .zahlaviodkazy a:hover,
.podzahlavim .zahlaviodkazy a:focus,
.podzahlavim .zahlaviodkazy a:active {
  text-decoration: none;
}

#znak {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (max-width: 1279px) {
  #znak {
    width: 57px;
  }
}

@media screen and (max-width: 399px) {
  #znak {
    width: 45px;
  }
}

#nazev {
  text-align: left;
  float: left;
  display: flex;
  position: relative;
  margin: 0 0 5px 0;
  padding: 0 0 0 92px;
}

@media screen and (max-width: 1279px) {
  #nazev {
    padding-left: 74px;
  }
}

@media screen and (max-width: 399px) {
  #nazev {
    padding-left: 60px;
  }
}

#nazev a {
  z-index: 30;
  display: inline;
  text-decoration: none !important;
  outline-width: 0;
  font-size: 0;
}

#nazev a::after {
  content: "";
}

#nazev img {
  font-size: 1rem;
  line-height: 1;
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obalobrazek {
  position: relative;
}

#obrazek {
  position: relative;
  padding-top: 42px;
  padding-bottom: 180px;
}

@media screen and (min-width: 601px) {
  #administraceobrazky {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 4;
  }
}

#blender {
  margin: 0 auto;
  z-index: 2;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #303030 url(images/blenderImages/0.jpg#u3a93af94) no-repeat 50% 50%;
  background-size: cover;
  display: none;
}

@media screen and (min-width: 601px) {
  #blender {
    display: block;
  }
}

.blender__buttons {
  position: absolute;
  z-index: 3;
  right: 1em;
  bottom: 1em;
}

.blender__button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
}

.blender__button:focus {
  box-shadow: none;
}

@keyframes blend-in {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.blender__item {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blender__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blender__item.blender__item--animating {
  animation: blend-in 800ms ease-out;
  animation-fill-mode: forwards;
}

#lista {
  text-align: right;
  padding: 0;
  position: relative;
}

#lista ul {
  position: relative;
}

#lista ul.inline {
  display: inline;
}

#lista li {
  margin: 0;
  white-space: nowrap;
}

#lista li.skip {
  position: absolute;
  display: block;
  top: 0 !important;
  right: 0 !important;
  max-width: 100%;
  padding: 1px;
  border: 0;
}

#lista li.skip a {
  text-align: center;
  border: 0;
  position: absolute;
  right: 0;
  top: -500px;
  z-index: 0;
  padding: 0 2px;
  background-color: #ffffff;
  color: #000000;
}

#lista li.skip a:focus,
#lista li.skip a:active {
  z-index: 10 !important;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

/*************************
* SEZNAMY
*************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/************************/
.odkazy .ui li li li::before,
.aktuality2 li.bezobr li li::before,
.utvary .ui li.o li li::before,
.kategorie .ui li li li::before,
.utvary .ui li li li::before,
.galerie .ui li li li::before {
  position: relative;
  top: -0.08em;
  margin-right: 7px;
  display: inline-block;
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='7px' height='7px' viewBox='0 0 7 7'%3E%3Cpath fill='%23bcbcbc' stroke='%23bcbcbc' stroke-miterlimit='10' d='M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z'/%3E%3C/svg%3E");
  vertical-align: middle;
  line-height: 0;
  overflow: hidden;
}

li.u, li.typsouboru {
  list-style-type: none !important;
}

li.u::before, li.typsouboru::before {
  content: normal !important;
}

.odkazy ol li::before::before {
  content: normal !important;
}

dl dt {
  font-size: 1.1875rem;
  color: #186D39;
  line-height: 1.1;
}

dl.kontakty .utvary {
  padding-top: 4px;
}

.ui li > strong:first-child {
  font-family: "Mukta Vaani";
  font-size: 1.4375rem;
}

.ui li > strong:first-child a,
.ui li > strong:first-child a:link,
.ui li > strong:first-child a:visited {
  color: #186D39;
}

.utvary .ui li > strong:first-child,
.souvisejiciodkazy .ui li > strong:first-child {
  font-family: "Mukta Vaani";
  font-size: 1rem;
  font-weight: bold;
}

.utvary .ui li > strong:first-child a,
.utvary .ui li > strong:first-child a:link,
.utvary .ui li > strong:first-child a:visited,
.souvisejiciodkazy .ui li > strong:first-child a,
.souvisejiciodkazy .ui li > strong:first-child a:link,
.souvisejiciodkazy .ui li > strong:first-child a:visited {
  color: #232323;
}

.ui li > strong:first-child img {
  font-size: 0.875rem;
}

.utvary .ui li li > strong:first-child,
.bodkazy .ui li li > strong:first-child,
.souvisejiciodkazy .ui li li > strong:first-child {
  font-weight: normal;
}

.ui ul li > strong:first-child {
  font-family: "Mukta Vaani";
  font-size: 1rem;
}

.ui ul li > strong:first-child a,
.ui ul li > strong:first-child a:link,
.ui ul li > strong:first-child a:visited {
  color: #232323;
}

.ui li {
  clear: none;
  padding: 0;
  margin: 0 0 5px 0;
}

.ui li strong {
  font-weight: normal;
  line-height: 1.2;
}

dl.kontakty .utvary .ui li strong + div,
#stromutvaru .utvary .ui li strong + div,
.odkazy .ui li strong + div {
  padding: 0;
  color: #666666;
  line-height: 1.4;
  font-style: italic;
}

dl.kontakty .utvary .ui li strong + div {
  margin-bottom: 10px;
}

.ui li .ui {
  padding-top: 0;
  padding-bottom: 10px;
}

.ui li .ui .ui {
  padding-bottom: 0;
}

.ui li .ui .ui .ui {
  padding-left: 18px;
}

.ui li li {
  margin: 5px 0;
}

.ui li li::after {
  content: normal;
}

.ui ol li {
  padding-left: 0;
}

.ui div {
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

dl.kontakty .utvary ul.ui {
  margin-left: 0;
  margin-bottom: 15px;
}

dl.kontakty .utvary li strong {
  font-size: 0.9375rem;
  font-family: "Mukta Vaani";
}

dl.kontakty .utvary li strong a,
dl.kontakty .utvary li strong a:link,
dl.kontakty .utvary li strong a:visited {
  color: #232323;
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/
#isvs .hodnota li::before {
  content: normal !important;
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/
.dok ul .inline li {
  display: inline;
}

.dok li {
  display: block;
  margin: 20px 0;
  padding: 0;
}

.dok li:first-child {
  margin-top: 8px;
}

.dok .n5-akce-typ,
.dok .dok-doplnek,
.dok .ktg,
.dok strong + span,
.dok .vd-priloha {
  font-size: 0.9375rem;
  color: #444444;
}

.dok .n5-akce-typ a,
.dok .n5-akce-typ a:link,
.dok .n5-akce-typ a:visited,
.dok .dok-doplnek a,
.dok .dok-doplnek a:link,
.dok .dok-doplnek a:visited,
.dok .ktg a,
.dok .ktg a:link,
.dok .ktg a:visited,
.dok strong + span a,
.dok strong + span a:link,
.dok strong + span a:visited,
.dok .vd-priloha a,
.dok .vd-priloha a:link,
.dok .vd-priloha a:visited {
  color: #444444;
}

.dok .vd-priloha {
  font-family: "Mukta Vaani";
  white-space: nowrap;
}

.dok strong {
  font-weight: normal;
}

.dok strong img {
  margin: 3px 20px 6px 0;
  float: left;
}

.dok strong a {
  font-size: 1.375rem;
  font-weight: bold;
  font-family: "Titillium Web";
  color: #222222;
  line-height: 1.36364;
}

#zpravodajstvi ul.inline {
  padding: 0;
  margin: 20px 0;
}

#kalendarAkci .dok li.u {
  padding-left: 150px;
}

#kalendarAkci .dok li.u strong img {
  margin-left: -150px;
}

/** DOKUMENTY NA TITULCE **/
#pozicovani {
  overflow: hidden;
}

#pozicovani .poz {
  margin-bottom: 25px;
}

@media screen and (min-width: 601px) {
  #pozicovani .cards ul {
    display: flex;
    flex-wrap: wrap;
  }
}

#pozicovani .cards li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
  margin-top: 0;
  margin-bottom: 25px;
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
  bottom: 0;
}

@media screen and (max-width: 599px) {
  #pozicovani .cards li {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  #pozicovani .cards li {
    width: 48%;
    margin-left: 4%;
  }
  #pozicovani .cards li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 650px) {
  #pozicovani .skryty {
    display: none;
  }
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ
*******************************/
.obrodkazy li {
  display: block;
  margin-bottom: 13px;
  background-image: none;
  position: relative;
}

.obrodkazy li::before {
  content: normal !important;
}

.obrodkazy a {
  font-size: 1.25rem;
  font-family: "Mukta Vaani";
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;
}

.obrodkazy strong {
  font-weight: normal;
}

.obrodkazy a,
.obrodkazy a:link,
.obrodkazy a:visited {
  text-decoration: none;
}

.obrodkazy a:hover,
.obrodkazy a:focus,
.obrodkazy a:active {
  text-decoration: underline;
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/
#vypisakci h3.cvi {
  font-size: 1.5625rem;
  margin-bottom: 10px;
}

#vypisakci .dok ul {
  display: flex;
  flex-wrap: wrap;
}

#vypisakci .dok li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
  margin-top: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding-bottom: 14px;
}

@media screen and (max-width: 599px) {
  #vypisakci .dok li {
    width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
  #vypisakci .dok li {
    width: 48%;
    margin-left: 4%;
  }
  #vypisakci .dok li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 900px) {
  #vypisakci .dok li {
    width: 30.66667%;
    margin-left: 4%;
  }
  #vypisakci .dok li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

#vypisakci .dok li.u img {
  width: 100%;
}

@media screen and (min-width: 500px) {
  #vypisakci .dok li {
    padding-left: 185px;
  }
  #vypisakci .dok li.u img {
    width: 160px;
    margin-left: -185px;
  }
}

#vypisakci .dok li .n5-akce-datum {
  color: #107437;
  font-size: 1.1875rem;
  font-family: "Titillium Web";
  margin-top: 8px;
}

#vypisakci .dok li .n5-akce-datum strong {
  font-weight: bold;
}

#vypisakci .dok li .n5-akce-typ {
  color: #6d6d6d;
  font-weight: 300;
  font-size: 1.0625rem;
}

#vypisakci .dok li .n5-akce-typ a,
#vypisakci .dok li .n5-akce-typ a:link,
#vypisakci .dok li .n5-akce-typ a:visited {
  color: #6d6d6d;
}

#vypisakci .dok li:not(.u) strong a::before {
  content: '';
  display: block;
  background: #d3d3d3 url(/aspinclude/vismoweb5/html/noimage.svg) no-repeat 50% 50%/80px auto;
  width: 160px;
  height: 105px;
  float: left;
  margin-left: -185px;
}

#vypisakci .dok li strong a,
#vypisakci .dok li strong a:link,
#vypisakci .dok li strong a:visited {
  color: #222222;
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/
.titulodkazy li {
  display: block;
  background-image: none;
  margin: 0 0 10px;
  padding: 0;
  position: relative;
}

.titulodkazy li::before {
  content: normal !important;
}

.titulodkazy li a {
  font-size: 1.0625rem;
}

.titulodkazy li strong:first-child a,
.titulodkazy li strong:first-child a:link,
.titulodkazy li strong:first-child a:visited {
  color: #232323;
}

.titulodkazy li div {
  margin: 2px 0 0 0;
}

.titulodkazy strong {
  font-weight: normal;
}

.dok li.urd-line {
  position: relative;
  margin: 0;
  padding: 15px 20px;
}

.dok li.urd-line:nth-child(even) {
  background-color: #ededed;
}

.dok li.urd-line::after {
  content: normal;
}

.dok li.urd-line .urd-left {
  position: relative;
}

.vismo--public .dok li.urd-line strong a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

@media screen and (min-width: 801px) {
  .dok li.urd-line {
    display: table;
    box-sizing: border-box;
    width: 100%;
  }
  .dok li.urd-line .urd-left,
  .dok li.urd-line .urd-right {
    display: table-cell;
  }
  .dok li.urd-line .urd-left {
    padding-right: 1.5em;
  }
  .dok li.urd-line .urd-right {
    width: 13em;
  }
}

.bodkazy .ui li > strong:first-child a,
.bodkazy .ui li > strong:first-child a:link,
.bodkazy .ui li > strong:first-child a:visited {
  color: #444444;
}

/**********************
*** DLAŽDICOVÉ MENU ***
**********************/
.mapa-stranek-2016 .odkazy.souvisejici ul {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 35px 0;
  list-style-type: none;
  padding: 0;
  text-align: center;
}

body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 739px) {
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 100%;
  }
}

@media screen and (min-width: 740px) and (max-width: 799px) {
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 48%;
    margin-left: 4%;
  }
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 800px) and (max-width: 899px) {
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 100%;
    margin-left: 4%;
  }
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(1n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 900px) and (max-width: 1023px) {
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 48%;
    margin-left: 4%;
  }
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 30.66667%;
    margin-left: 4%;
  }
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 649px) {
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 100%;
  }
}

@media screen and (min-width: 650px) and (max-width: 879px) {
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 48%;
    margin-left: 4%;
  }
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 880px) and (max-width: 1023px) {
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 30.66667%;
    margin-left: 4%;
  }
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 22%;
    margin-left: 4%;
  }
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

.mapa-stranek-2016 .odkazy.souvisejici ul li {
  box-sizing: border-box;
  padding: 12px 0;
  background-clip: padding-box;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: left;
  position: relative;
}

.mapa-stranek-2016 .odkazy.souvisejici ul li strong + div {
  font-style: normal;
}

.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child {
  font-weight: normal;
}

.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a {
  font-weight: bold;
  font-family: "Titillium Web";
  font-size: 1.375rem;
  display: block;
  padding: 0 0 8px;
}

.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a,
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:link,
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:visited {
  color: #186D39;
  text-decoration: none;
}

.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:hover,
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:focus,
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:active {
  color: #222222;
  text-decoration: underline;
}

.temata {
  margin-top: -120px;
  position: relative;
  z-index: 6;
  padding: 50px 0;
  background-repeat: no-repeat, no-repeat;
  background-image: url(images/bg_wm.svg), linear-gradient(to bottom, rgba(240, 240, 240, 0.5) 0px, #F0F0F0 85px, #F0F0F0 100%);
  background-position: 105% 100%, 0 0;
}

.temata .ui {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.temata .ui li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
  margin-bottom: 25px;
}

@media screen and (max-width: 599px) {
  .temata .ui li {
    width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
  .temata .ui li {
    width: 48%;
    margin-left: 4%;
  }
  .temata .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 900px) and (max-width: 1099px) {
  .temata .ui li {
    width: 30.66667%;
    margin-left: 4%;
  }
  .temata .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1100px) {
  .temata .ui li {
    width: 22%;
    margin-left: 4%;
  }
  .temata .ui li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

.temata .ui li > strong:first-child a,
.temata .ui li > strong:first-child a:link,
.temata .ui li > strong:first-child a:visited {
  color: #186D39;
  font-family: "Titillium Web";
  font-size: 1.375rem;
  text-decoration: none;
  font-weight: bold;
}

.temata .ui li > strong:first-child a:hover,
.temata .ui li > strong:first-child a:focus,
.temata .ui li > strong:first-child a:active {
  color: #222222;
  text-decoration: underline;
}

.obrodkazy-partners {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.obrodkazy-partners .ui {
  list-style-type: none;
  margin: 25px 0 0;
  padding: 0;
}

.obrodkazy-partners li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 0px) {
  .obrodkazy-partners li {
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 599px) {
  .obrodkazy-partners li {
    width: 100%;
    margin-left: 0%;
  }
  .obrodkazy-partners li:nth-of-type(1n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
  .obrodkazy-partners li {
    width: calc( 50% - 10px);
    margin-left: 20px;
  }
  .obrodkazy-partners li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 900px) {
  .obrodkazy-partners li {
    width: calc( 25% - 15px);
    margin-left: 20px;
  }
  .obrodkazy-partners li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

.obrodkazy-partners li div {
  width: 100%;
}

.obrodkazy-partners li > strong:first-child img {
  float: none;
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16 / 9;
  margin-bottom: 25px;
}

.obrodkazy-partners a {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  text-decoration: none;
}

.obrodkazy-partners a:hover .mtext,
.obrodkazy-partners a:focus .mtext,
.obrodkazy-partners a:active .mtext {
  text-decoration: none;
}

/*****************************
* FORMULÁŘE
*****************************/
/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/
.titulformular label, #hledani label {
  line-height: 1.2;
  text-align: left;
  padding: 3px 2px;
  display: block;
}

.titulformular label.label-skryty, #hledani label.label-skryty {
  padding: 0 2px;
  position: relative;
}

.titulformular label.label-skryty::after, #hledani label.label-skryty::after {
  content: "";
  z-index: 2;
}

/****************************/
/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/
.format {
  background-color: #ffffff;
  color: #000000;
  height: 35px;
  border-radius: 0;
  line-height: 1.21429;
  font-size: 0.9375rem;
}

.format:-ms-input-placeholder {
  color: inherit !important;
}

.format::-ms-input-placeholder {
  color: inherit !important;
}

.format::placeholder {
  color: inherit !important;
}

.format:focus {
  border-color: #107437;
}

.format,
fieldset {
  border: 1px #cccccc solid;
}

:not(.fvlevo) > .fvyraz #hv {
  font-size: 1.1875rem;
  height: 44px;
  border-width: 2px;
}

textarea.format {
  min-height: 70px;
}

select.format[multiple] {
  height: 105px;
}

.btn {
  background-color: #107437;
  border: 1px #107437 solid;
  color: #ffffff;
  height: 35px;
}

.fbtn .btn {
  padding: 0 2em;
  font-size: 1rem;
}

/************************************************/
.fkont {
  margin-top: 20px;
}

.fkont form {
  padding: 21px 36px 23px 36px;
}

.fkont :not(.fvlevo) > .fvyraz label[for="hv"] {
  font-size: 1rem;
}

.formular,
.fkont form,
.nastaveni {
  background-color: #eeeeee;
  color: #202020;
}

.formular a,
.formular a:link,
.formular a:visited,
.fkont form a,
.fkont form a:link,
.fkont form a:visited,
.nastaveni a,
.nastaveni a:link,
.nastaveni a:visited {
  color: #202020;
}

.fcesta,
.fcesta a, .fcesta a:link, .fcesta a:visited, .fcesta a:hover, selector:focus, selector:active {
  background-color: #107437;
  color: #ffffff;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/
.titulformular label.label-skryty::after {
  background: transparent url(images/frm_prekryti.svg) repeat 0 0;
}

.titulformular .format {
  margin: 2px 0;
  padding: 5px 15px;
  width: 100%;
  border-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.titulformular img {
  position: absolute;
  right: 0;
  top: 0;
}

.titulformular .opiste {
  position: relative;
}

.titulformular .captchaformat {
  padding-right: 90px;
}

.titulformular .fbtn,
.titulformular .btn {
  clear: none;
  margin: 0;
}

.titulformular .btn {
  display: inline-block;
}

.titulformular p {
  padding: 0 0 10px 0;
  margin: 0;
}

/***************
*	HLEDÁNÍ
***************/
#hledani {
  margin: 0;
  width: 100%;
}

@media screen and (min-width: 401px) {
  #hledani {
    width: 270px;
    margin-left: 20px;
  }
}

#hledani .pole {
  position: relative;
  z-index: 5;
}

#hledani label {
  position: absolute !important;
  left: -99999999em;
}

#hledani label.label-skryty::after {
  background: transparent url(images/hl_prekryti.svg) repeat 0 0;
}

#hledani input:focus {
  outline: 0;
}

#hledani .format {
  width: 100%;
  margin: 0;
  height: 48px;
  border-color: #B5D3C0;
  background-color: #ffffff;
  color: #5f6368;
  padding: 4px 50px 4px 10px !important;
  background-repeat: no-repeat;
  background-position: 0 50%;
}

#hledani ::placeholder {
  color: transparent !important;
}

#hledani .btn {
  border: 0;
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 11px;
  height: auto;
  border-radius: 0;
  background-color: transparent;
  color: #ffffff;
}

.inline-form {
  padding-bottom: 3em;
}

.inline-form #skryvaci,
.inline-form-word {
  display: inline-block;
}

.inline-form-search {
  position: relative;
}

.inline-form-search input,
.inline-form-search label,
.inline-form-search textarea,
.inline-form-search select {
  font-size: 1rem;
}

.inline-form-word {
  margin: .25em 0;
}

.inline-form-input {
  box-sizing: border-box;
  max-width: 12em;
  padding: 2px 10px;
}

.inline-form-datepicker {
  padding-right: 30px;
  background-image: url(/aspinclude/vismoWeb5/html/images/abonent_calendar_2016.svg);
  background-size: 18px auto;
  background-repeat: no-repeat;
  background-position: 98% 48%;
  max-width: 8em;
}

.inline-form-input,
.inline-form-select {
  border-radius: 4px;
  border: 1px #888888 solid;
  height: 1.75em;
}

.inline-form .fbtn {
  margin-bottom: -3em;
}

.inline-form-select {
  max-width: 13em;
  padding-right: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow: ellipsis;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='15px' height='15px' viewBox='0 0 15 15' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23444444' d='M4,0l4,7H0L4,0z M4,15L0,8h8L4,15z'/%3E%3C/svg%3E");
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

.inline-form #prepinac {
  margin-top: 2em;
}

@media screen and (min-width: 601px) {
  .inline-form #prepinac {
    position: absolute;
    left: 0;
    bottom: -2.3em;
    font-size: 1rem;
  }
}

/***********************
*	TABULKY
***********************/
.blokposun {
  background-color: #eeeeee;
}

.seznam {
  border: 0;
}

.seznam th a,
.seznam th a:link,
.seznam th a:visited,
.seznam th a:hover,
.seznam th a:focus,
.seznam th a:active {
  color: #ffffff;
}

.seznam td,
.seznam th {
  border: 1px white solid;
  border-width: 0 1px 1px 0;
}

.seznam td:first-child,
.seznam th:first-child {
  padding-left: 36px;
}

.seznam td:last-child,
.seznam th:last-child {
  border-right: 0;
}

.seznam th {
  font-size: 1.125rem;
  font-family: "Mukta Vaani";
  line-height: 1.1;
  font-weight: normal;
  background-color: #107437;
  color: #ffffff;
}

.seznam th a,
.seznam th a:link,
.seznam th a:visited {
  color: #ffffff;
}

.seznam td {
  color: black;
  border-width: 1px 1px 0 0;
}

.seznam .b1 td {
  border-color: #ffffff;
}

.seznam .b2 td, .seznam #isvs .hodnota td, #isvs .seznam .hodnota td {
  border-color: #ebebeb;
}

.b1 {
  background-color: #ebebeb;
}

.b2, #isvs .hodnota {
  background-color: #ffffff;
}

/**********************
*	FOTOGALERIE
**********************/
.nahledy li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 399px) {
  .nahledy li {
    width: 100%;
  }
}

@media screen and (min-width: 400px) and (max-width: 799px) {
  .nahledy li {
    width: 49%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  .nahledy li {
    width: 32%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .nahledy li {
    width: 23.5%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

.nahledy div > a {
  background: #cccccc;
  border: 2px transparent solid;
  border-radius: 4px;
  min-height: 200px;
}

.nahledy div > a:hover,
.nahledy div > a:focus,
.nahledy div > a:active {
  background: transparent;
  border-color: #107437;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 399px) {
  .galerie-2016 .ui li {
    width: 100%;
  }
}

@media screen and (min-width: 400px) and (max-width: 1023px) {
  .galerie-2016 .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .galerie-2016 .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

/***********************
*	ZÁLOŽKY
***********************/
.zalozky {
  font-size: 1.25rem;
  line-height: 1.1;
  font-family: "Mukta Vaani";
  font-weight: normal;
}

.zalozky li {
  padding: 0;
  margin: 0 .5em .5em 0;
  float: left;
  position: relative;
  display: block;
}

.zalozky li.azalozka {
  background: transparent none;
}

.zalozky a {
  display: block;
  text-decoration: none;
  margin: 0;
  position: relative;
  z-index: 20;
  cursor: pointer;
  padding: 10px 36px 12px;
}

.zalozky a,
.zalozky a:link,
.zalozky a:visited {
  background-color: turquoise;
  color: #433923;
}

.zalozky a:hover,
.zalozky a:focus,
.zalozky a:active {
  text-decoration: underline;
}

.zalozky .azalozka a,
.zalozky .azalozka a:link,
.zalozky .azalozka a:visited,
.zalozky .azalozka a:hover,
.zalozky .azalozka a:focus,
.zalozky .azalozka a:active {
  background-color: bisque;
  color: #000000;
  text-decoration: none;
  cursor: default;
}

#zahlavi2 {
  margin: 20px 0;
}

#zalozkynadpis {
  padding: 15px 0 0 0;
}

.vych_pol {
  font-size: 1rem;
  text-align: right;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}

.vych_pol strong {
  font-weight: normal;
}

a[id*="k0"] {
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
  color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/
.bezjs {
  padding: 20px 0 15px 0;
}

.bezjs .inline li::before {
  color: #404040;
}

#keskryti {
  display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/
#titul .zalozky {
  font-size: 1.3125rem;
  line-height: 1.1;
  font-family: "Mukta Vaani";
  text-transform: uppercase;
}

#titul .zalozky .inline li::before {
  content: normal;
}

#titul .zalozky a {
  padding: 15px;
}

#titul .zalozky a,
#titul .zalozky a:link,
#titul .zalozky a:visited {
  background-color: turquoise;
  color: #433923;
}

#titul .zalozky a:hover,
#titul .zalozky a:focus,
#titul .zalozky a:active {
  text-decoration: underline;
}

#titul .zalozky .azalozka a,
#titul .zalozky .azalozka a:link,
#titul .zalozky .azalozka a:visited,
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:focus,
#titul .zalozky .azalozka a:active {
  background-color: bisque;
  color: #000000;
}

@media screen and (max-width: 649px) {
  #linkynakotvy {
    display: none;
  }
  #titul .poz h2.cist {
    position: static;
    width: auto;
    height: auto;
  }
  #titul .poz .skryty {
    display: block;
  }
}

/******************
* MODULY
******************/
.logo {
  margin: 0 0 10px;
}

@media screen and (max-width: 599px) {
  .logo {
    text-align: center;
  }
}

#uvodtitul {
  overflow: hidden;
  margin: 0 0 30px 0;
}

#upozorneni {
  padding: 15px;
  background-color: pink;
}

#upozorneni,
#upozorneni h2.cvi,
#upozorneni a, #upozorneni a:link, #upozorneni a:visited {
  color: #444444;
}

#upozorneni h2.cvi,
#upozorneni p {
  display: inline-block;
}

.inpocasi {
  color: #ffffff;
}

#uhodiny .uhden {
  display: inline-block;
  width: 4.4em;
}

#kalendarAkci .editor {
  margin-top: 40px;
  margin-bottom: 40px;
}

#obalmbannery {
  min-width: 320px;
  background: #ffffff;
}

#mbannery {
  padding: 30px 0;
  color: #000000;
  margin: 0;
}

#mbannery .editor {
  text-align: center;
}

#mbannery .editor a,
#mbannery .editor a:link,
#mbannery .editor a:visited,
#mbannery .editor a:hover,
#mbannery .editor a:focus,
#mbannery .editor a:active {
  color: #000000 !important;
}

#mbannery li {
  margin-top: 0;
  margin-bottom: 0;
}

.kontaktpata .socialni {
  text-align: left;
  padding: 0;
  margin-top: 20px;
}

.kontaktpata .socialni li {
  margin: 0 10px 10px 0;
}

.kontaktpata .socialni li::before {
  content: normal;
}

#jmeniny {
  padding: 10px 12px 9px 94px;
  text-align: left;
}

@media screen and (max-width: 1279px) {
  #jmeniny {
    padding-left: 76px;
  }
}

#jmeniny * {
  display: inline;
}

#kontakt {
  background-color: rgba(0, 0, 0, 0.65);
  color: #ffffff;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 12px;
}

#kontakt h2.cvi {
  font-size: 2rem;
  color: inherit;
}

#kontakt a,
#kontakt a:link,
#kontakt a:visited {
  color: inherit;
}

#kontakt address {
  display: block;
  font-style: normal;
  margin: 0;
}

#kontakt strong {
  font-weight: bold;
}

@media screen and (min-width: 600px) {
  #kontakt {
    max-width: 256px;
  }
}

#anketa p {
  padding: 0;
  margin: 0 0 1em 0;
}

#anketa ul {
  list-style-type: none;
  padding: 0;
  margin: 8px 0 5px 0;
}

#anketa ul li {
  margin-bottom: 8px;
}

#anketa .hlas {
  margin-top: 3px;
  z-index: 1;
}

#anketa .hlas div {
  height: 12px;
  background-color: #107437;
}

#pocitadlo {
  margin: 15px 0 0 0;
  text-align: center;
  padding-bottom: 15px;
}

#pocitadlo ul.inline {
  padding-left: 0;
  display: inline;
}

#pocitadlo ul.inline li span {
  margin: 0 0 0 2px;
}

@media screen and (max-width: 599px) {
  #pocitadlo li {
    display: block !important;
  }
  #pocitadlo li + li::before {
    content: "";
    margin: 0;
  }
}

/*******************************
* KALENDÁŘE
*******************************/
.mesice {
  display: flex;
  justify-content: space-between;
  color: #2a2a2a;
}

#stranka .mesice .c-actual-month {
  text-align: center;
}

#kalakci {
  max-width: 30em;
  width: 104%;
  margin-left: -2%;
}

@media screen and (min-width: 451px) {
  #kalakci {
    width: 106%;
    margin-left: -3%;
  }
}

#kalakci .calcells {
  width: 100%;
  border-spacing: 0 1.5em;
  font-size: 0.875rem;
}

#kalakci .calcells td,
#kalakci .calcells th {
  box-sizing: border-box;
  position: relative;
}

#kalakci .calcells th {
  top: -6px;
  color: #2a2a2a;
}

#kalakci .calcells td {
  width: 14.28%;
  height: 2em;
  text-align: center;
  font-size: 0.875rem;
  z-index: 0;
}

#kalakci .calcells td::before {
  content: "";
  display: block;
  width: 2em;
  height: 2em;
  border-radius: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1em 0 0 -1em;
  box-sizing: border-box;
  z-index: -1;
}

#kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px blue solid;
}

#kalakci .calcells td.wkday, #kalakci .calcells td.wkday a {
  color: #2a2a2a;
  text-decoration: none;
}

#kalakci .calcells td.curdate::before {
  background-color: magenta;
}

#kalakci .calcells td.curdate, #kalakci .calcells td.curdate a {
  color: #ffffff;
}

#kalakci .calcells td.curdate:hover, #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}

#kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px blue solid;
}

#kalakci .calcells td.notmnth, #kalakci .calcells td.notmnth a {
  color: #2a2a2a;
  text-decoration: none;
}

#kalakci .calcells td.notmnth a > span {
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
}

#kalakci .calcells td a {
  line-height: 2em;
  position: absolute;
  width: 2em;
  left: 50%;
  margin-left: -1em;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
}

#kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
  text-decoration: underline;
  border-radius: 0;
}

#kalakci .calcells td a > span {
  font-weight: normal;
  font-size: 0.75rem;
  position: absolute;
  top: -.9em;
  right: -.4em;
  width: 1.6em;
  height: 1.6em;
  line-height: 1.7em;
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
  border-radius: 500em;
}

@media screen and (min-width: 451px) {
  #kalakci .calcells td {
    height: 2.5em;
  }
  #kalakci .calcells td::before {
    width: 2.5em;
    height: 2.5em;
    margin: -1.25em 0 0 -1.25em;
  }
  #kalakci .calcells td a {
    line-height: 2.5em;
    width: 2.5em;
    margin-left: -1.25em;
  }
  #kalakci .calcells td a > span {
    right: -.7em;
  }
}

@media screen and (min-width: 601px) {
  #kalakci .calcells {
    font-size: 1rem;
  }
  #kalakci .calcells td, #kalakci .calcells td a {
    font-size: 1rem;
  }
  #kalakci .calcells td a > span {
    font-size: 0.875rem;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #kalakci .calcells td {
    line-height: 2em;
  }
  #kalakci .calcells td::before {
    margin-top: 0;
    top: 0;
  }
  #kalakci .calcells td a {
    bottom: auto;
  }
}

.akce-podle-data {
  clear: both;
}

#stranka #kalakci {
  margin-top: 10px;
  margin-bottom: 25px;
  max-width: 41.25em;
  float: left;
}

#stranka #kalakci .c-arrow {
  display: inline-block;
}

#stranka #kalakci .c-arrow:hover {
  text-decoration: underline;
}

#stranka #kalakci .c-before-month, #stranka #kalakci .c-next-month {
  padding: 0;
}

#stranka #kalakci .c-before-month .space, #stranka #kalakci .c-before-month .c-moth, #stranka #kalakci .c-before-month .c-year, #stranka #kalakci .c-next-month .space, #stranka #kalakci .c-next-month .c-moth, #stranka #kalakci .c-next-month .c-year {
  display: none;
}

#stranka #kalakci .c-actual-month {
  padding-left: 0;
  text-align: center;
}

#stranka #kalakci .mesice {
  margin: 0 auto;
  display: table;
  width: 100%;
  line-height: .8;
  table-layout: fixed;
  border-spacing: 1px 0;
  margin-bottom: 10px;
  font-size: 1.25rem;
}

#stranka #kalakci .mesice .c-actual-month {
  display: table-cell;
  width: 71%;
  vertical-align: bottom;
}

#stranka #kalakci .mesice + p {
  margin: 20px 0;
}

#stranka #kalakci .mesice a {
  display: table-cell;
  vertical-align: bottom;
  text-decoration: underline;
  color: #232323;
  text-align: center;
}

#stranka #kalakci .mesice a:hover {
  text-decoration: none !important;
}

#stranka #kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px #107437 solid;
}

#stranka #kalakci .calcells td.wkday, #stranka #kalakci .calcells td.wkday a {
  color: #222222;
}

#stranka #kalakci .calcells td.curdate::before {
  background-color: #107437;
}

#stranka #kalakci .calcells td.curdate, #stranka #kalakci .calcells td.curdate a {
  color: #ffffff;
}

#stranka #kalakci .calcells td.curdate:hover, #stranka #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}

#stranka #kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px #107437 solid;
}

#stranka #kalakci .calcells td.notmnth, #stranka #kalakci .calcells td.notmnth a {
  color: #222222;
}

#stranka #kalakci .calcells td.notmnth a > span {
  background-color: #186D39;
  border: 1px #186D39 solid;
  color: #ffffff;
}

#stranka #kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
}

#stranka #kalakci .calcells td a > span {
  background-color: #186D39;
  border: 1px #186D39 solid;
  color: #ffffff;
}

/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/
/****************************************/
/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/
#obalmenu1 {
  position: relative;
  z-index: 15;
  overflow: hidden;
}

#menu1 li {
  font-size: 1.375rem;
  line-height: 1.1;
  margin: 0 18px;
}

@media screen and (min-width: 801px) {
  #menu1 li {
    display: inline-block;
  }
}

#menu1 li.akt {
  font-weight: bold;
}

#menu1 li div {
  display: inline;
}

#menu1 li a {
  display: block;
  font-family: "Titillium Web";
  padding: 14px 0;
}

@media screen and (min-width: 1280px) {
  #menu1 li a {
    padding: 26px 0;
  }
}

@media screen and (min-width: 801px) {
  #menu1 li a {
    display: inline-block;
  }
}

#menu1 li a,
#menu1 li a:link,
#menu1 li a:visited {
  text-decoration: none;
  color: #ffffff;
  border-bottom: 3px transparent solid;
}

#menu1 li a:hover,
#menu1 li a:focus,
#menu1 li a:active {
  border-bottom-color: #ffffff;
}

#menu1 li #osmakth,
#menu1 li #osmakth:link,
#menu1 li #osmakth:visited,
#menu1 li #osmakth:hover,
#menu1 li #osmakth:focus,
#menu1 li #osmakth:active {
  cursor: text;
  text-decoration: none;
}

/**********************
	*** DLAŽDICOVÉ MENU ***
	**********************/
.nadmenu {
  display: block;
  padding: 88px 20px 12px 42px;
  font-family: "Mukta Vaani";
  font-size: 2rem;
  line-height: 1.1;
  color: #222222;
  font-weight: bold;
}

.nadmenu a,
.nadmenu a:link,
.nadmenu a:visited {
  font-family: "Titillium Web";
  color: #107437;
  text-decoration: none;
}

.nadmenu a:hover,
.nadmenu a:focus,
.nadmenu a:active {
  text-decoration: underline;
}

.nadmenu a {
  position: relative;
}

.nadmenu a::before {
  content: url(images/sipka_back.svg);
  width: 19px;
  height: 23px;
  position: absolute;
  top: .45em;
  left: -42px;
}

/**********************
	* MOBILNÍ MENU
	**********************/
#menu {
  background-color: transparent;
  padding: 20px;
}

#menu ul {
  padding-left: 20px;
}

#menu ul a {
  padding: 5px 20px;
  margin-left: -20px;
  display: block;
}

#menu ul a,
#menu ul a:link,
#menu ul a:visited {
  color: #222222;
}

#menu ul #osmakt,
#menu ul #osmakt:link,
#menu ul #osmakt:visited,
#menu ul #osmakt:hover,
#menu ul #osmakt:focus,
#menu ul #osmakt:active {
  background-color: transparent;
  color: #222222;
  text-decoration: none;
}

/***********************
	* DESKTOPOVÉ MENU
	***********************/
@media screen and (min-width: 801px) {
  .nadmenu {
    display: block;
  }
  #nadmenu {
    font-size: 0.875rem;
    display: none;
    font-weight: normal;
    padding: 0;
    white-space: nowrap;
  }
  #menu {
    background-color: transparent;
    padding: 0 0 50px 0;
  }
  #menu ul {
    font-size: 1.25rem;
    line-height: 1.2;
    padding: 28px 0 5px 0;
  }
  #menu ul a,
  #menu ul a:link,
  #menu ul a:visited {
    color: #222222;
    text-decoration: none;
  }
  #menu ul .aktodk,
  #menu ul .aktodk:link,
  #menu ul .aktodk:visited {
    color: #232323;
    text-decoration: none;
  }
  #menu ul a:hover,
  #menu ul a:focus,
  #menu ul a:active {
    text-decoration: underline;
  }
  #menu ul #osmakth,
  #menu ul #osmakth:link,
  #menu ul #osmakth:visited,
  #menu ul #osmakth:hover,
  #menu ul #osmakth:focus,
  #menu ul #osmakth:active {
    background-color: transparent;
    text-decoration: none;
    font-weight: bold;
    cursor: default;
    color: #232323;
    border: 0;
  }
  #menu ul a {
    padding: 10px 0 10px 0;
    display: block;
    position: relative;
    z-index: 10;
    margin-left: 0;
    text-decoration: none;
  }
  #menu li {
    margin: 0;
    padding: 0 20px 0 0;
    position: relative;
    display: block;
  }
}

#google_translate_element {
  display: inline-block;
  position: relative;
  top: -1px;
}

#google_translate_element.pole {
  background-color: transparent;
  margin-left: 15px;
}

#google_translate_element .goog-te-gadget-simple {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
}

#google_translate_element .goog-te-gadget-simple,
#google_translate_element .goog-te-gadget-simple * {
  font-size: 1rem !important;
}

#google_translate_element .goog-te-gadget-simple .goog-te-gadget-icon {
  background-color: #ffffff;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] {
  margin-right: 0;
  display: inline-block;
  text-decoration: none !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span {
  padding: 0 3px;
  display: inline-block;
  color: #000000;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(1), #google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(2) {
  display: none;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(3) {
  color: #ffffff !important;
  font-size: 0.875rem !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] img {
  display: none;
}

@media screen and (max-width: 599px) {
  #google_translate_element,
  #goog-gt-tt,
  #goog-gt-tt + div > object,
  iframe.goog-te-menu-frame,
  iframe.goog-te-banner-frame {
    display: none !important;
  }
  body {
    top: 0 !important;
  }
}
