
/*************************
* ŠABLONY
*************************/


/*************************
* DOKUMENTY
*************************/


%card {
   margin-top: 0;
   overflow: hidden;
   box-sizing: border-box;
   flex-grow: 1;

   img {
      float: none;
      display: table;
      margin: 0;
      width: 100%;
   }

   .dok-datum {
      margin-top: 10px;
      font-weight: bold;
      color: #222222;
      font-family: $font-headings;
      font-size: rem(19px);
   }

   .dok-upoutavka {
       width: 100%;
      display: block;

      &:empty::before {
         content: "";
         display: block;
         background: #ffffff url(images/noimage.svg) no-repeat 50% 50% / 80px auto;
         width: 160px;
         height: 105px;
      }
   }

   .dok-ktg {
      color: #6D6D6D;
      font-weight: 300;
      font-size: rem(17px);

      @include link {
         color: #6D6D6D;
      }
   }

   // .dok-nazev {
   //    display: block;
   // }

   div {
      margin-top: 8px;
   }
   @include min(500px){
    padding-left: 185px;
    .dok-upoutavka {
        float: left;
        display: block;
        margin-left: -185px;
        width: auto;
        img {
            margin-bottom: 10px;
            width: 160px;
         }
    }
   }
}
