

/*************************
* ZÁHLAVÍ
*************************/


/*************************
* HLAVA
*************************/
 
#obalhlava {
	position: relative;
	z-index: 13;
	background-color: $color-main;
	color: #ffffff;
	
	#titul & {
		border-top: 18px #ffffff solid;
	}

	::selection {
		background-color: #ffffff;
		color: #222222;
	}

	@include link {
		color: #ffffff;
	}
} 

#hlava {
	clear: both;
	position: relative;
	z-index: 3;
}

.hlava {
	display: flex;
	justify-content: space-between;
	align-items: center;	
	//padding: 15px 0;

	@include bp("< 1280px") {
		flex-wrap: wrap;
	}
	
	&__section {
		margin: 15px 0;
		display: flex;
		align-items: center;
		
		@include bp("< 600px") {
			&:last-child {
				flex-wrap: wrap;
				//flex-basis: 300px;
			}
		}

		&.-middle {
			margin: 0;

			@include bp("< 1280px") {
				padding-top: 10px;
				order: 3;
				width: 100%;
			}
		}
	}
}

.podzahlavim {
	position: relative;
	z-index: 5;
	display: flex;
    flex-wrap: wrap;
	justify-content: space-between;
    > div{
        width:100%;
    }
    @include min(600px){
        > div{
            width:auto;
        }   
    }

	&::after {
		content: normal !important;	
	}

	.zahlaviodkazy {
		display: flex;
		flex-direction: column;

		a {
			display: inline-block;
			padding: 22px 20px;
			background-color: rgba(#000000, .65);
			text-align: center;
			min-width: 9.35em;
			min-height: 9.35em;
			font-family: $font-headings;
			box-sizing: border-box;

			&.webkamera {
				font-weight: normal;
				margin-bottom: 20px;
				padding-top: 100px;
				background: {
					image: url(images/webkamera.svg);
					repeat: no-repeat;
					position: 50% 25px;
				}
				
				span {
					font-size: rem(20px);
				}
			}

			&.odkpocasi {
				text-decoration: none !important;

				#pocasi_teplota_vnejsi_nejnovejsi { 
					font: {
						size: rem(38px);
						weight: 600;
					}
				}

				#pocasi_vlhkost_vnejsi_nejnovejsi { 
					font: {
						size: rem(22px);
						weight: normal;
					}
				}
			}
		}

		@include link { 
			color: #ffffff;
			text-decoration: underline;
		}

		@include link-over { 
			text-decoration: none;
		}
	}
}

#znak {
	margin: 0;
	position: absolute;
	left: 0;
	top: 0;

	@include bp("< 1280px") {
		width: 57px;
	}

	@include bp("< 400px") {
		width: 45px;
	}
}


#nazev {
	text-align: left;
	float: left;
	display: flex;
	position: relative;
	margin: 0 0 5px 0;
	padding: 0 0 0 92px;

	@include bp("< 1280px") {
		padding-left: 74px;
	}

	@include bp("< 400px") {
		padding-left: 60px;
	}

	a {
		z-index: 30;
		display: inline;
		text-decoration: none !important;
		outline-width: 0;
		font-size: 0;

		&::after {
			content: "";
			@extend %fill;
		}
	}

	img {
		font-size: rem(16px);
		line-height: 1;
	}
}


/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/

#obalobrazek {
	position: relative;
}

#obrazek {
	position: relative;
	padding-top: 42px;
	padding-bottom: 180px;
}

@include bp("> 600px") {
	#administraceobrazky {
		position: absolute;
		left: 10px;
		top: 10px;
	 	z-index: 4;
	}
}

#blender {
	margin: 0 auto;
	z-index: 2;
	overflow: hidden;
	@include fill;
	background: #303030 url(images/blenderImages/0.jpg##{$cache-hash}) no-repeat 50% 50%;
	background-size: cover;
	display: none;

	@include bp("> 600px") {
		display: block;
	}
}

.blender__buttons {
	position: absolute;
	z-index: 3;
	right: 1em;
	bottom: 1em;
}

.blender__button {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent none;
	box-shadow: none;

	&:focus {
		box-shadow: none;
	}
}


@keyframes blend-in {
	from {
		display: block;
		opacity: 0;
	  } to {
	  display: block;
		opacity: 1;
	}
}

.blender__item {
	background: {
		position: 50% 50%;
		repeat: no-repeat;
		size: cover;
	}
}

.blender__item {
	@include fill;
}

.blender__item.blender__item--animating {
	animation: blend-in 800ms ease-out;
	animation-fill-mode: forwards;
}


#lista {
	@extend %sf;
	text-align: right;
	padding: 0;
	position: relative;

	ul {
		@extend %reset-list;
		position: relative;

		&.inline {
			display: inline;
		}
	}

	li {
		margin: 0;
		white-space: nowrap;

		&.skip {
			position: absolute;
			display: block;
			top: 0 !important;
			right: 0 !important;
			max-width: 100%;
			padding: 1px;
			border: 0;

			a {
				text-align: center;
				border: 0;
				position: absolute;
				right: 0;
				top: -500px;
				z-index: 0;
				padding: 0 2px;
				background-color: #ffffff;
				color: #000000;
			}
			
			a:focus,
			a:active {
				z-index: 10 !important;
				position: absolute !important;
				right: 10px;
				top: 5px;
			}
		}
	}
}
