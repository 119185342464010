
/*****************************
* FORMULÁŘE
*****************************/

/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/

%hiding-label {
	line-height: 1.2;
	text-align: left;
	padding: 3px 2px;
	display: block;

	&.label-skryty {
		padding: 0 2px;
		position: relative;
		
		&::after {
			@extend %fill;
			content: "";
			z-index: 2;
		}
	}
}

/****************************/


/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/

.format {
	background-color: #ffffff;
	color: #000000;
	height: $format-height;
	border-radius: 0;
	line-height: ( 17 / 14 );
	font-size: rem(15px);

	&:-ms-input-placeholder {
		color: inherit !important;
	}

	&::-ms-input-placeholder {
		color: inherit !important;
	}

	&::placeholder {
		color: inherit !important;
	}

	&:focus {
		border-color: $format-border-focus;
	}
}

.format,
fieldset {
	border: 1px $format-border solid;
}

:not(.fvlevo) > .fvyraz #hv {
	font-size: rem(19px);
	height: $format-main-height;
	border-width: 2px;
}

textarea.format {
	min-height: $format-height * 2;
}

select.format[multiple] {
	height: $format-height * 3;
}

.btn {
	background-color: $btn-background;
	border: $btn-border;
	color: $btn-color;
	height: $btn-height;
}

.fbtn .btn {
	padding: 0 2em;
	font-size: rem(16px);
}

/************************************************/

.fkont {
	margin-top: 20px;

	form {
		padding: $form-padding;
	}

	:not(.fvlevo) > .fvyraz label[for="hv"] {
		font-size: rem(16px);
	}
}

.formular,
.fkont form,
.nastaveni {
	background-color: $form-background;
	color: $form-text;

	@include link {
		color: $form-link;
	}
}

//zjistit, kde to vůbec je kromě diskuze
.fcesta,
#{selector-link-all(".fcesta a")} {
	background-color: $th-background;
	color: $th-text;
}


/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/

.titulformular {
	label {
		@extend %hiding-label;

		&.label-skryty::after {
			background: transparent url(images/frm_prekryti.svg) repeat 0 0;
		}
	}

	.format {
		margin: 2px 0;
		padding: 5px 15px;
		width: 100%;
		border-color: #ffffff;
		background: {
			repeat: no-repeat;
			position: 0 0;
		}
	}

	img {
		position: absolute;
		right: 0;
		top: 0;
	}

	.opiste {
		position: relative;
	}

	.captchaformat {
		padding-right: 90px;
	}

	.fbtn,
	.btn {
		clear: none;
		margin: 0;
	}
	
	.btn {
		display: inline-block;
	}
	
	p {
		padding: 0 0 10px 0;
		margin: 0;
	}
}


/***************
*	HLEDÁNÍ
***************/

#hledani {
	margin: 0;
	width: 100%;

	@include bp("> 400px") {
		//float: right;
		width: 270px;
		margin-left: 20px;
	}

	.pole {
		position: relative;
		z-index: 5;
	}

	label {
        position: absolute!important;
        left: -99999999em;
	 	@extend %hiding-label;

	 	&.label-skryty::after {
	 		background: transparent url(images/hl_prekryti.svg) repeat 0 0;
	 	}
	}

	input:focus {
		outline: 0;
	}
	
	.format {
		width: 100%;
		margin: 0;
		height: 48px;
		border-color: #B5D3C0;
		background-color: #ffffff;
		color: #5f6368;
		padding: 4px 50px 4px 10px!important;
		background: {
			repeat: no-repeat;
			position: 0 50%;
		}
	}
    ::placeholder{
        color: transparent!important;
    }

	.btn {
		@extend %reset;
		border: 0;
		position: absolute;
		z-index: 2;
		top: 8px;
		right: 11px;
		height: auto;
		border-radius: 0;
		background-color: transparent;
		color: #ffffff;
	}
}

.inline-form {
	padding-bottom: 3em;
}

.inline-form #skryvaci,
.inline-form-word {
	display: inline-block;
}

.inline-form-search {
	position: relative;

	input,
	label,
	textarea,
	select {
		font-size: 1rem;		
	}
}

.inline-form-word {
	margin: .25em 0;
}

.inline-form-input {
	box-sizing: border-box;
	max-width: 12em;
	padding: 2px 10px;
}

.inline-form-datepicker {
	padding-right: 30px;
	background-image: url(/aspinclude/vismoWeb5/html/images/abonent_calendar_2016.svg);
	background-size: 18px auto;
	background-repeat: no-repeat;
	background-position: 98% 48%;
	max-width: 8em;
}

.inline-form-input,
.inline-form-select {
	border-radius: 4px;
	border: 1px #888888 solid;
	height: 1.75em; 
}

.inline-form .fbtn {
	margin-bottom: -3em;
}

.inline-form-select {
	max-width: 13em;
	padding-right: 25px;
	-webkit-appearance: none; 
	-moz-appearance: none; 
	appearance: none;
	text-overflow: ellipsis;
	background: {
		image: encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 15 15" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" fill="#444444" d="M4,0l4,7H0L4,0z M4,15L0,8h8L4,15z"/></svg>');
		position: 100% 50%;
		repeat: no-repeat;
	}
}

.inline-form #prepinac {
	margin-top: 2em;
	
	@include bp("> 600px") {
		position: absolute;
		left: 0;
		bottom: -2.3em;
		font-size: 1rem;
	}
}