/*************************
* SEZNAMY
*************************/

/*************************
* MIXINY PRO ODRÁŽKY
*************************/

@mixin bullet($bg: #bcbcbc, $border: $bg, $top: -0.08em) {
   &::before {
      @extend %border-box;
      position: relative;
      top: $top;
      margin-right: 7px;
      display: inline-block;
      content: encode-svg(
         '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="7px" height="7px" viewBox="0 0 7 7"><path fill="#{$bg}" stroke="#{$border}" stroke-miterlimit="10" d="M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z"/></svg>'
      );
      vertical-align: middle;
      line-height: 0;
      overflow: hidden;

      @content;
   }
}

@mixin no-bullet {
   &::before {
      content: normal !important;
   }
}

@mixin dlazdice-ul {
   display: flex;
   flex-wrap: wrap;
   margin: 20px 0 35px 0;
   list-style-type: none;
   padding: 0;
   text-align: center;

   body.leftpanel & li {
      @include grid(740px 2 4%, 800px 1, 900px 2, 1024px 3);
   }

   body.noleftpanel & li {
      @include grid(650px 2 4%, 880px 3, 1024px 4);
   }

   li {
      box-sizing: border-box;
      padding: 12px 0;
      background-clip: padding-box;
      margin-top: 0;
      margin-bottom: 30px;
      text-align: left;
      position: relative;

      strong + div {
         font-style: normal;
      }

      > strong:first-child {
         font-weight: normal;

         a {
            font-weight: bold;
            font-family: $font-headings;
            font-size: rem(22px);
            display: block;
            padding: 0 0 8px;

            &::before {
               content: '';
               @include fill;
            }
         }

         @include link {
            color: $color-main-accent;
            text-decoration: none;
         }

         @include link-over {
            color: #222222;
            text-decoration: underline;
         }

         // @include link-over {
         // 	text-decoration: underline;
         // }
      }
   }
}

/************************/

li {
   .odkazy .ui & li li,
   .aktuality2 &.bezobr li li,
   .utvary .ui &.o li li,
   .kategorie .ui & li li,
   .utvary .ui & li li,
   .galerie .ui & li li {
      @include bullet;
   }

   &.u,
   &.typsouboru {
      @include no-bullet;
      list-style-type: none !important;
   }

   .odkazy ol &::before {
      @include no-bullet;
   }
}

dl dt {
   font-size: rem(19px);
   color: $list-top-level-link-color;
   line-height: 1.1;
}

dl.kontakty .utvary {
   padding-top: 4px;
}

.ui {
   li > strong:first-child {
      font-family: $font;
      font-size: rem(23px);

      @include link {
         color: $list-top-level-link-color;
      }

      .utvary &,
      .souvisejiciodkazy & {
         font-family: $font;
         font-size: rem(16px);
         font-weight: bold;

         @include link {
            color: $color-base-link;
         }
      }

      img {
         font-size: rem(14px);
      }
   }

   li li > strong:first-child {
      .utvary &,
      .bodkazy &,
      .souvisejiciodkazy & {
         font-weight: normal;
      }
   }

   ul li > strong:first-child {
      font-family: $font;
      font-size: rem(16px);

      @include link {
         color: $color-base-link;
      }
   }

   li {
      clear: none;
      padding: 0;
      margin: 0 0 5px 0;

      strong {
         font-weight: normal;
         line-height: 1.2;

         dl.kontakty .utvary &,
         #stromutvaru .utvary &,
         .odkazy & {
            + div {
               padding: 0;
               color: #666666;
               line-height: 1.4;
               font-style: italic;
            }
         }

         dl.kontakty .utvary & + div {
            margin-bottom: 10px;
         }
      }

      .ui {
         padding-top: 0;
         padding-bottom: 10px;

         .ui {
            padding-bottom: 0;

            .ui {
               padding-left: 18px;
            }
         }
      }

      li {
         margin: 5px 0;

         &::after {
            content: normal;
         }
      }
   }

   ol li {
      padding-left: 0;
   }

   div {
      font-weight: normal;
      margin-bottom: 2px;
      margin-top: 6px;
      padding-top: 0;
      padding-bottom: 0;
   }
}

dl.kontakty .utvary {
   ul.ui {
      margin-left: 0;
      margin-bottom: 15px;
   }

   li {
      strong {
         font-size: rem(15px);
         font-family: $font;

         @include link {
            color: $color-base-link;
         }
      }
   }
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/

#isvs .hodnota {
   @extend .b2;

   li {
      @include no-bullet;
   }
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/

.dok {
   ul {
      @extend %reset-list;

      .inline li {
         display: inline;
      }
   }

   li {
      display: block;
      margin: 20px 0;
      padding: 0;

      &:first-child {
         margin-top: 8px;
      }
   }

   .n5-akce-typ,
   .dok-doplnek,
   .ktg,
   strong + span,
   .vd-priloha {
      font-size: rem(15px);
      color: #444444;

      @include link {
         color: #444444;
      }
   }

   .vd-priloha {
      font-family: $font;
      white-space: nowrap;
   }

   strong {
      font-weight: normal;

      img {
         margin: 3px 20px 6px 0;
         float: left;
      }

      a {
         font-size: rem(22px);
         font-weight: bold;
         font-family: $font-headings;
         color: $document-link-color;
         line-height: (30 / 22);
      }
   }
}

#zpravodajstvi {
   ul.inline {
      padding: 0;
      margin: 20px 0;
   }
}

#kalendarAkci .dok {
   li.u {
      padding-left: 150px;

      strong img {
         margin-left: -150px;
      }
   }
}

/** DOKUMENTY NA TITULCE **/

#pozicovani {
   overflow: hidden;

   .poz {
      margin-bottom: 25px;
   }

   .cards {
      ul {
         @include bp('> 600px') {
            display: flex;
            flex-wrap: wrap;
         }
      }

      li {
         @include grid(600px 2 4%);
         margin-top: 0;
         margin-bottom: 25px;
         box-sizing: border-box;
         padding-top: 0;
         padding-bottom: 0;
         bottom: 0;
      }

      .card {
         @extend %card;
      }
   }

   @include bp('>= 650px') {
      .skryty {
         display: none;
      }
   }
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ
*******************************/

.obrodkazy {
   .ui {
      @extend %reset-list;
   }

   li {
      @include no-bullet;
      @extend %reset;
      display: block;
      margin-bottom: 13px;
      background-image: none;
      position: relative;
   }

   a {
      font-size: rem(20px);
      font-family: $font;
      background-repeat: no-repeat;
      background-position: 0 50%;
      padding-left: 65px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 48px;
   }

   strong {
      font-weight: normal;
   }

   @include link {
      text-decoration: none;
   }

   @include link-over {
      text-decoration: underline;
   }
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/

#vypisakci {
   h3.cvi {
      font-size: rem(25px);
      margin-bottom: 10px;
   }

   .dok {
      ul {
         display: flex;
         flex-wrap: wrap;
      }

      li {
         @include grid(600px 2 4%, 900px 3);
         margin-top: 0;
         box-sizing: border-box;
         margin-bottom: 20px;
         padding-bottom: 14px;

         &.u {
            img {
               width: 100%;
            }
         }
         @include min(500px) {
            padding-left: 185px;
            &.u {
               img {
                  width: 160px;
                  margin-left: -185px;
               }
            }
         }

         .n5-akce-datum {
            color: $color-main;
            font-size: rem(19px);
            font-family: $font-headings;
            margin-top: 8px;

            strong {
               font-weight: bold;
            }
         }

         .n5-akce-typ {
            color: #6d6d6d;
            font-weight: 300;
            font-size: rem(17px);

            @include link {
               color: #6d6d6d;
            }
         }

         &:not(.u) {
            strong a::before {
               content: '';
               display: block;
               background: #d3d3d3 url(/aspinclude/vismoweb5/html/noimage.svg) no-repeat 50% 50% / 80px auto;
               width: 160px;
               height: 105px;
               float: left;
               margin-left: -185px;
            }
         }

         strong {
            @include link {
               color: #222222;
            }
         }
      }
   }
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/

.titulodkazy {
   .ui {
      @extend %reset-list;
   }

   li {
      @include no-bullet;
      display: block;
      background-image: none;
      margin: 0 0 10px;
      padding: 0;
      position: relative;

      a {
         font-size: rem(17px);
      }

      strong:first-child {
         @include link {
            color: $color-base-link;
         }
      }

      div {
         margin: 2px 0 0 0;
      }
   }

   strong {
      font-weight: normal;
   }
}

.dok li.urd-line {
   position: relative;
   margin: 0;
   padding: 15px 20px;

   &:nth-child(even) {
      background-color: #ededed;
   }

   &::after {
      content: normal;
   }

   .urd-left {
      position: relative;
   }

   .vismo--public & {
      strong a::after {
         @include fill;
         content: '';
      }
   }

   @include bp('> 800px') {
      display: table;
      box-sizing: border-box;
      width: 100%;

      .urd-left,
      .urd-right {
         display: table-cell;
      }

      .urd-left {
         padding-right: 1.5em;
      }

      .urd-right {
         width: 13em;
      }
   }
}

.bodkazy .ui li > strong:first-child {
   @include link {
      color: #444444;
   }
}

/**********************
*** DLAŽDICOVÉ MENU ***
**********************/

@if $bool_dlazdice == true {
   .mapa-stranek-2016 .odkazy.souvisejici ul {
      @include dlazdice-ul;
   }
}

.temata {
   margin-top: -120px;
   position: relative;
   z-index: 6;
   padding: 50px 0;
   background: {
      repeat: no-repeat, no-repeat;
      image: url(images/bg_wm.svg),
         linear-gradient(to bottom, rgba($color-light, 0.5) 0px, $color-light 85px, $color-light 100%);
      position: 105% 100%, 0 0;
   }

   .ui {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
         @include grid(600px 2 4%, 900px 3, 1100px 4);
         margin-bottom: 25px;

         > strong:first-child {
            @include link {
               color: $color-main-accent;
               font-family: $font-headings;
               font-size: rem(22px);
               text-decoration: none;
               font-weight: bold;
            }

            @include link-over {
               color: #222222;
               text-decoration: underline;
            }
         }
      }
   }
}

.obrodkazy-partners {
   padding-top: 4rem;
   padding-bottom: 4rem;
   .ui {
      list-style-type: none;
      margin: 25px 0 0;
      padding: 0;
   }
   li {
      @include grid(1px 1 0, 600px 2 20px, 900px 4);
      display: flex;
      flex-direction: column;
      div {
         width: 100%;
      }

      > strong:first-child {
         img {
            float: none;
            width: 100%;
            object-fit: cover;
            object-position: center;
            aspect-ratio: 16 / 9;
            margin-bottom: 25px;
         }
      }
   }
   a {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      text-decoration: none;
   }
   @include link-over {
      .mtext {
         text-decoration: none;
      }
   }
}
