
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/

$web_bitmask: 1 !global; // Bitová maska pro nastavení stylů - více v _bity.scss
$bool_dlazdice: true !global; // Zapíná kompilaci dlaždicových stylů

$sans-serif: Arial, Helvetica, sans-serif;
$font: "Mukta Vaani";
$font-headings: "Titillium Web";

$color-base: #444444;       //základní barva textu
$color-base-link: #232323;  //základní barva odkazů
$color-main: #107437; 
$color-main-accent: #186D39; 

$color-light: #F0F0F0;

$max-width: 1230px;

$link-focus-bg: #303030;    //background odkazů při tabování
$link-focus-text: #ffffff;  //text odkazů při tabování

/************************/

/*************************
* PROMĚNNÉ KOMPONENT
*************************/

/** TYPOGRAFIE **/

$h2-size: 40px;
$h3-size: 35px;
$h4-size: 30px;


/** FORMULÁŘE **/

$form-background:     #eeeeee;             // pozadí formulářů
$form-padding:        21px 36px 23px 36px;   // odsazení formulářů
$form-text:           #202020;             // barva písma ve formuláři
$form-link:           #202020;             // barva odkazů ve formuláři
$format-height:       35px;                  // výška běžných inputů a selectů ve formulářích
$format-main-height:  44px;                  // výška hlavního inputu ve formuláři
$format-border:       #cccccc;             // barva okrajů inputů
$format-border-focus: $color-main;             // barva okrajů inputů
$btn-height:          35px;                  // výška tlačítek ve formulářích
$btn-background:      $color-main;              // pozadí tlačítek
$btn-border:          1px $color-main solid;     // border tlačítek
$btn-color:           #ffffff;             // barva textu tlačítek

/** TABULKY **/

$b2-background: #ffffff;
$b1-background: #ebebeb;
$th-background: $color-main;
$th-text:       #ffffff;

/** SEZNAMY **/

$document-link-color: #222222;        // barva odkazů ve výpisu dokumentů
$list-top-level-link-color: $color-main-accent;  // barva velkých odkazů v první úrovni seznamů

/** AKCE **/

$event-day-size: 22px;              // velikost písma v cedulce - den 
$event-month-size: 16px;            // velikost písma v cedulce - den 
$event-link-color: orange;        // barva odkazů ve výpisu akcí 
$event-day-background: gold;      // pozadí dne cedulky v kalendáři
$event-day-text: #101010;         // text dne cedulky v kalendáři
$event-month-background: #444444; // pozadí měsíce cedulky v kalendáři
$event-month-text: #ffffff;       // text měsíce cedulky v kalendáři

/** KALENDÁŘE **/

/** Hodnoty je lepší zadávat v em **/

$cal-cell-max-width: 30em;			// max. šířka kalendáře na titulce
$cal-cell-sub-max-width: 41.25em;	// max. šířka kalendáře na podstránce
$cal-cell-spacing-vertical: 1.5em;	// vertikální mezera mezi buňkami
$cal-cell-radius: 0;			// kulaté rohy

// styly kalendáře na titulce
$cal-cell-title-month-background: transparent;				// pozadí buňky měsíce
$cal-cell-title-month-border: 2px blue solid;		// rámeček měsíce
$cal-cell-title-month-text: #2a2a2a;				// text buňky měsíce
$cal-cell-title-notmonth-background: transparent;			// pozadí buňky mimo měsíc
$cal-cell-title-notmonth-border: 1px blue solid;	// rámeček mimo měsíc
$cal-cell-title-notmonth-text: #2a2a2a;				// text buňky mimo měsíc
$cal-cell-hover-background: transparent;				// pozadí buňky po najetí myši
$cal-cell-hover-text: #2a2a2a;						// text buňky po najetí myši
$cal-cell-title-current-day-background: magenta;	// pozadí aktuálního dne
$cal-cell-title-current-day-text: #ffffff;			// text aktuálního dne

$cal-indicator-background: yellow;					// pozadí buňky počtu akcí
$cal-indicator-border: 0px transparent solid;					// rámeček buňky počtu akcí
$cal-indicator-text: #2a2a2a;						// text buňky počtu akcí
$cal-indicator-notmnth-background: $cal-indicator-background;				// pozadí buňky počtu akcí mimo měsíc
$cal-indicator-notmnth-border: $cal-indicator-border;					// rámeček buňky počtu akcí mimo měsíc
$cal-indicator-notmnth-text: $cal-indicator-text;						// text buňky počtu akcí mimo měsíc

// styly kalendáře na podstránce
$cal-cell-sub-month-background: transparent;
$cal-cell-sub-month-border: 2px $color-main solid;
$cal-cell-sub-month-text: #222222;
$cal-cell-sub-notmonth-background: transparent;
$cal-cell-sub-notmonth-border: 1px $color-main solid;
$cal-cell-sub-notmonth-text: #222222;
$cal-cell-sub-hover-background: transparent;
$cal-cell-sub-hover-text: #2a2a2a;
$cal-cell-sub-current-day-background: $color-main;
$cal-cell-sub-current-day-text: #ffffff;

$cal-sub-indicator-background: $color-main-accent;
$cal-sub-indicator-border: 1px $color-main-accent solid;
$cal-sub-indicator-text: #ffffff;
$cal-sub-indicator-notmnth-background: $cal-sub-indicator-background;					
$cal-sub-indicator-notmnth-border: $cal-sub-indicator-border;					
$cal-sub-indicator-notmnth-text: $cal-sub-indicator-text;						


// $cal-cell-padding: 7px; //padding buňky - top a bottom

// $cal-title-month-background: #270722;      //pozadí buňky v měsíci
// $cal-title-month-text: #ffffff;            //text buňky v měsíci
// $cal-title-notmonth-background: #d8cfaf;   //pozadí buňky mimo měsíc
// $cal-title-notmonth-text: #000000;         //text buňky mimo měsíc
// $cal-title-current-border: #ffffff;        //barva rámečku aktuálního dne

// $cal-hover-background: #ffffff;            //pozadí buňky po najetí myši
// $cal-hover-text: #000000;                  //text buňky po najetí myši

// $cal-sub-month-background: $cal-title-month-background;
// $cal-sub-month-text: $cal-title-month-text;
// $cal-sub-notmonth-background: $cal-title-notmonth-background;
// $cal-sub-notmonth-text: $cal-title-notmonth-text;
// $cal-sub-current-border: $cal-title-current-border;
// $cal-sub-selected-background: #B0030C;
// $cal-sub-selected-text: #ffffff;

/** MENU **/

$menu-tablet-size:  800px;     // hranice mezi vyjížděcím/statickým menu
$menu-bg:           transparent; // pozadí menu
$menu-link-color:   #222222; // barva odkazů a písma v menu
$menu-topbar-bg:    $color-main;    // barva horní lišty značící aktuální umístění 
$menu-topbar-text:  #ffffff; // text a ikony v horní liště
$menu-trigger-bg:   $color-main;   // barva pozadí tlačítka pro otevření menu 
$menu-trigger-text: #ffffff; // text v tlačítku
$menu-active-bg:    transparent;  // pozadí aktivní položky mobilního menu
$menu-active-text:  #222222;  // text aktivní položky mobilního menu

/** ZÁLOŽKY **/

$tab-background: turquoise;           //pozadí záložky
$tab-text: #433923;                   //text záložky
$tab-selected-background: bisque;     //pozadí aktivní záložky
$tab-selected-text: #000000;          //text aktivní záložky

$tab-sub-background: $tab-background;       //pozadí záložky - podstránky
$tab-sub-text: $tab-text;               //text záložky - podstránky
$tab-sub-selected-background: $tab-selected-background; //pozadí aktivní záložky - podstránky
$tab-sub-selected-text: $tab-selected-text;      //text aktivní záložky - podstránky

/** PATA **/

$footer-background: #ffffff;
$footer-text: #444444;
$footer-link: $footer-text;

/************************/


/***********************************************************
* PROMĚNNÉ BREAKPOINTŮ PRO SNADNĚJŠÍ ZADÁVÁNÍ MIXINU "BP"
***********************************************************/

$v400: "> 400px";
$v500: "> 500px";
$v600: "> 600px";
$v700: "> 700px";
$v800: "> 800px";
$v900: "> 900px";
$v1024: "> 1024px";
$v1280: "> 1280px";

$v400v: ">= 400px";
$v500v: ">= 500px";
$v600v: ">= 600px";
$v700v: ">= 700px";
$v800v: ">= 800px";
$v900v: ">= 900px";
$v1024v: ">= 1024px";
$v1280v: ">= 1280px";

$m400: "< 400px";
$m500: "< 500px";
$m600: "< 600px";
$m700: "< 700px";
$m800: "< 800px";
$m900: "< 900px";
$m1024: "< 1024px";
$m1280: "< 1280px";

$m400v: "<= 400px";
$m500v: "<= 500px";
$m600v: "<= 600px";
$m700v: "<= 700px";
$m800v: "<= 800px";
$m900v: "<= 900px";
$m1024v: "<= 1024px";
$m1280v: "<= 1280px";

/**********************************************************/

$_wh: #ffffff;
$_bl: #000000;