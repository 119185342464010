
/*******************************
* KALENDÁŘE
*******************************/

.mesice {
	display: flex;
	justify-content: space-between;
	color: $cal-cell-title-month-text;

	#stranka & .c-actual-month {
		text-align: center;
	}
}

#kalakci {
	max-width: $cal-cell-max-width;
	width: 104%;
	margin-left: -2%;

	@include bp("> 450px"){
		width: 106%;
		margin-left: -3%;
	}

	.calcells {
		width: 100%;
		border-spacing: 0 $cal-cell-spacing-vertical;
		font-size: rem(14px);

		td,
		th {
			box-sizing: border-box;
			position: relative;
		}

		th{
			top: -6px;
			color: $cal-cell-title-month-text;
		}

		td {
			width: 14.28%;
			height: 2em;
			text-align: center;
			font-size: rem(14px);
			z-index: 0;

			&::before{
				content: "";
				display: block;
				width: 2em;
				height: 2em;
				border-radius: $cal-cell-radius;
				position:absolute;
				left: 50%;
				top: 50%; 
				margin: -1em 0 0 -1em;
				box-sizing:border-box;
				z-index: -1;
			}

			&.wkday{
				&::before {
					background-color: $cal-cell-title-month-background;
					border: $cal-cell-title-month-border;
				}	
				
				&, a{
					color: $cal-cell-title-month-text;
					text-decoration: none;
				}
			}

			&.curdate{
				&::before {
					background-color: $cal-cell-title-current-day-background;
				}

				&, a{
					color: $cal-cell-title-current-day-text;

					&:hover{
						color: $cal-cell-title-current-day-text!important;
					}
				}	
			}

			&.notmnth{
				&::before{
					background-color: $cal-cell-title-notmonth-background;
					border: $cal-cell-title-notmonth-border;
				}

				&, a{
					color: $cal-cell-title-notmonth-text;
					text-decoration: none;
				}

				a > span {
					background-color: $cal-indicator-notmnth-background;
					border: $cal-indicator-notmnth-border;
					color: $cal-indicator-notmnth-text;
				}
			}

			.desc {
				@extend %vh;
			}

			a {
				line-height: 2em;
				position: absolute;
				width: 2em;
				left: 50%;
				margin-left: -1em;
				top: 0;
				bottom: 0;
				font-size: rem(14px);

				&:hover {
					background-color: $cal-cell-hover-background !important;
					color: $cal-cell-hover-text !important;
					text-decoration: underline;
					border-radius: $cal-cell-radius;
				}	
								
				> span {
					font-weight: normal;
					font-size: rem(12px);
					position: absolute;
					top: -.9em;
					right: -.4em;
					width: 1.6em;
					height: 1.6em;
					line-height: 1.7em;
					background-color: $cal-indicator-background;
					border: $cal-indicator-border;
					color: $cal-indicator-text;
					border-radius: 500em;
				}
			}
		}

		@include bp("> 450px"){
			td{
				height: 2.5em;

				&::before{
					width: 2.5em;
					height: 2.5em;
					margin: -1.25em 0 0 -1.25em;
				}

				a {
					line-height: 2.5em;
					width: 2.5em;
					margin-left: -1.25em;

					> span{
						right: -.7em;
					}
				}	
			}
		}

		@include bp("> 600px"){
			font-size: rem(16px);

			td, td a {
				font-size: rem(16px);
			}	

			td a > span{
				font-size: rem(14px);
			}
		}

		@include msie-only {
			td{
				line-height: 2em;

				&::before{
					margin-top: 0;
					top: 0;
				}
				
				a{
					bottom: auto;
				}	
			}
		}	
	}
}

.akce-podle-data{
	clear: both;
}

#stranka #kalakci{
	margin-top: 10px;
	margin-bottom: 25px;
	max-width: $cal-cell-sub-max-width;
	float: left;

	.c-arrow{
		display: inline-block;

		&:hover{
			text-decoration: underline;
		}
	}

	.c-before-month, .c-next-month{
		padding: 0;

		.space, .c-moth, .c-year{
			display: none;
		}
	}

	.c-actual-month{
		padding-left: 0;
		text-align: center;
	}

	.mesice{
		margin: 0 auto;
		@extend %reset;
		display: table;
		width: 100%;
		line-height: .8;
		table-layout: fixed;
		border-spacing: 1px 0;
		margin-bottom: 10px;
		font-size: rem(20px);

		.c-actual-month{
			display: table-cell;
			width: 71%;
			vertical-align: bottom;
		}

		+ p {
			margin: 20px 0;
		}

		a {
			display: table-cell;
			vertical-align: bottom;
			text-decoration: underline;
			color: $color-base-link;
			text-align: center;

			&:hover{
				text-decoration: none!important;
			}
		}
	}

	.calcells {
		td {
			&.wkday{
				&::before {
					background-color: $cal-cell-sub-month-background;
					border: $cal-cell-sub-month-border;
				}	
				
				&, a{
					color: $cal-cell-sub-month-text;
				}
			}

			&.curdate{
				&::before {
					background-color: $cal-cell-sub-current-day-background;
				}

				&, a{
					color: $cal-cell-sub-current-day-text;

					&:hover{
						color: $cal-cell-sub-current-day-text!important;
					}
				}	
			}

			&.notmnth{
				&::before{
					background-color: $cal-cell-sub-notmonth-background;
					border: $cal-cell-sub-notmonth-border;
				}

				&, a{
					color: $cal-cell-sub-notmonth-text;
				}

				a > span {
					background-color: $cal-sub-indicator-notmnth-background;
					border: $cal-sub-indicator-notmnth-border;
					color: $cal-sub-indicator-notmnth-text;
				}
			}

			a {
				&:hover {
					background-color: $cal-cell-sub-hover-background !important;
					color: $cal-cell-sub-hover-text !important;
				}	
								
				> span {
					background-color: $cal-sub-indicator-background;
					border: $cal-sub-indicator-border;
					color: $cal-sub-indicator-text;
				}
			}
		}
	}
}	